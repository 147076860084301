<div class="wrapper">
    <app-header></app-header>
    <div class="page-content">
        <div id="title">
            <h2>DICHIARAZIONE SOSTITUTIVA</h2>
            <p>(ai sensi degli articoli 46 e 47 del D.P.R. 28/12/2000, n° 445)</p>
        </div>
        <div class="alert alert-secondary alert-box text_2 sezione-dichiarazione">
            <form name='form' id="anagraficaDichiaranteForm" role="form" [formGroup]="anagraficaDichiaranteForm">

                <div class="row align-items-center">
                    <p>Il/La sottoscritto/a <b>{{utente.name}} {{utente.lastName}} </b></p>
                    <p>Codice Fiscale <b>{{codiceFiscaleDichiarante}}</b></p>
                </div>
                <div class="row align-items-center">
                    <p></p>
                </div>
                <div class="row align-items-center">
                    <p>nato/a a</p>
                    <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazione" placeholder="*Comune"
                        [className]="(!this.anagraficaDichiaranteForm.get('comuneDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazione'"
                        formControlName='comuneDichiarante'>
                    <p>in provincia di</p>
                    <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazione"
                        [className]="(!this.anagraficaDichiaranteForm.get('provinciaDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazione'"
                        placeholder="*Provincia" formControlName='provinciaDichiarante' maxlength="2">
                    <p>il</p>
                    <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazione"
                        [className]="(!this.anagraficaDichiaranteForm.get('dataNascitaDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazione'"
                        placeholder="*gg/mm/yyyy" formControlName='dataNascitaDichiarante'>

                </div>

                <div class="row align-items-center">
                    <p>Email</p>
                    <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazioneEmail"
                        [className]="(!this.anagraficaDichiaranteForm.get('emailDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazioneEmail'"
                        placeholder="*email@email.it" formControlName='emailDichiarante'>
                    <p>Conferma Email</p>
                    <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazioneEmail"
                        [className]="(!this.anagraficaDichiaranteForm.get('emailDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazioneEmail'"
                        placeholder="*email@email.it" formControlName='confermaEmailDichiarante'
                        (paste)="preventPaste()">
                </div>
                <div class="row align-items-center">
                    <p>Telefono</p>
                    <input type="text" id="inputTextDichiarazione" class="inputTextDichiarazione"
                        [className]="(!this.anagraficaDichiaranteForm.get('cellDichiarante').valid && noValidForm) ? 'inputTextDichiarazioneError' : 'inputTextDichiarazione'"
                        placeholder="*0000000000" formControlName='cellDichiarante'>
                </div>
            </form>
            <form name='form' role="form" [formGroup]="consensoForm">
                <div class="row justify-content-center">
                    <p><b>*DICHIARO</b></p>
                </div>
                <div class="row justify-content-center">
                    <div *ngIf="this.consensoForm.get('dichiaro').valid && noValidForm" class="form-errors">
                        Obbligatorio</div>
                </div>
                <div class="row justify-content-start checkBox-section">
                    <div>
                        <input type="checkbox" class="form-check-input" id="dichiaro" formControlName='dichiaro'>
                    </div>
                    <p>di essere il responsabile delle RSA selezionate nell'elenco sottostante</p>
                </div>
                <div class="row justify-content-center">
                    <p><b>*CHIEDO</b></p>
                </div>
                <div class="row justify-content-center">
                    <div *ngIf="this.consensoForm.get('chiedo').valid && noValidForm" class="form-errors">
                        Obbligatorio</div>
                </div>
                <div class="row justify-content-start checkBox-section">
                    <div>
                        <input type="checkbox" class="form-check-input" id="chiedo" formControlName='chiedo'>
                    </div>
                    <p>di essere registrato ai fini dell'accesso al portale RSA Sicure per la generazione dei codici
                        per la prenotazione dei test di screening delle persone in RSA</p>
                </div>
            </form>

        </div>

        <div id="title">
            <h2>SELEZIONA RSA</h2>
            <h6><b>NOTA BENE:</b> è necessario selezionare tutte le RSA di cui si è responsabili</h6>
        </div>
        <form name='form' role="form" [formGroup]="ricercaIstitutiForm">
            <!-- <div class="row">
                <div class="form-group col-md-4 col-12 ">
                    <label class="col-12 col-md-4">Tipo RSA: </label>
                    <select class="form-control form-textEditor form-control-sm col-12 col-md-6" type="select"
                        id='tipoIstituto' formControlName='tipoIstituto' (change)="getIstitutiCensimento()">
                        <option *ngFor="let tipoIstituto of listaTipoIstituto" value="{{tipoIstituto.codTipoIstituto}}">
                            {{tipoIstituto.descTipoIstituto}}</option>
                    </select>
                </div>
                <div class="form-group col-md-4 col-12  ">
                    <label class="col-12 col-md-4">Organismo RSA: </label>
                    <select class="form-control form-textEditor form-control-sm col-12 col-md-6" type="select"
                        id='organismoScolastico' formControlName='organismoScolastico'
                        (change)="getIstitutiCensimento()">
                        <option value=''>- Seleziona un tipo organismo -</option>
                        <option *ngFor="let tipoOrganismoScolastico of listaTipoOrganismoScolastico"
                            value="{{tipoOrganismoScolastico}}">
                            {{tipoOrganismoScolastico}}</option>
                    </select>
                </div>
            </div> -->

            <div class="row">
                <div class="form-group col-md-4 col-12  ">
                    <label class="col-12 col-md-4">Provincia: </label>
                    <select class="form-control form-textEditor form-control-sm col-12 col-md-6" type="select"
                        id='provincia' formControlName='provincia' (change)="onChangeProvincia()">
                        <option value=''>- Seleziona una provincia -</option>
                        <option *ngFor="let provincia of listaProvince" value="{{provincia}}">{{provincia}}</option>
                    </select>
                    <!-- <validation-messages [control]="ricercaIstitutiForm.controls.provincia"></validation-messages> -->
                </div>
                <div class="form-group col-md-4 col-12  ">
                    <label class="col-12 col-md-4">Comune: </label>
                    <select class="form-control form-textEditor form-control-sm col-12 col-md-6" type="select"
                        id='comune' formControlName='comune' (change)="getIstitutiCensimento()">
                        <option *ngIf="(this.ricercaIstitutiForm.value.provincia == '')" value=''>- Seleziona una
                            provincia -</option>
                        <option *ngIf="!(this.ricercaIstitutiForm.value.provincia == '')" value=''>- Seleziona un comune
                            -
                        </option>
                        <option *ngFor="let comune of listaComuni" value="{{comune}}">
                            {{comune}}</option>
                    </select>
                    <!-- <validation-messages [control]="ricercaIstitutiForm.controls.provincia"></validation-messages> -->
                </div>
            </div>

            <div class="form-group col-md-8 col-12">
                <label class=" col-12 col-md-2">Denominazione RSA: </label>
                <input class="form-control form-textEditor form-control-sm col-12 col-md-12" type="text"
                    id='denominazioneIstituto' formControlName='denominazioneIstituto'
                    (change)="getIstitutiCensimento()">
                <!-- <validation-messages [control]="ricercaIstitutiForm.controls.denominazioneIstituto"></validation-messages> -->
            </div>
        </form>

        <div id="selezionaIstitutiBox">
            <div class="row container-custom-element-select">
                <div class="container-boxIstituti">
                    <label class="control-label ">Tutte le RSA: </label>
                    <div class="boxIstituti">
                        <p *ngFor="let istituto of istitutiNonSelezionati " id="{{istituto.denominazioneIstituto}}"
                            (click)="selectIstitutoElement(istituto)"
                            [className]="istituto.selected ? 'element-select-istituti element-select-istituti-selected' : 'element-select-istituti'">
                            {{istituto.codScuola}} - {{istituto.denominazioneIstituto}}</p>
                    </div>
                </div>
                <div class="container-button-seleziona">
                    <div class="row justify-content-center ">
                        <button class="btn  button-seleziona-istituto" (click)="aggiornaListeIstituti(true)">
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </div>
                    <div class="row justify-content-center">
                        <button class="btn  button-seleziona-istituto" (click)="aggiornaListeIstituti(false)">
                            <i class="fas fa-chevron-left"></i>
                        </button>
                    </div>
                    <div class="row justify-content-center ">
                        <button class="btn  button-seleziona-istituto-mobile" (click)="aggiornaListeIstituti(true)">
                            <i class="fas fa-chevron-down"></i>
                        </button>
                        <button class="btn  button-seleziona-istituto-mobile" (click)="aggiornaListeIstituti(false)">
                            <i class="fas fa-chevron-up"></i>
                        </button>
                    </div>
                </div>
                <div class="container-boxIstituti">
                    <label class="control-label ">RSA selezionate: </label>
                    <div class="boxIstituti">
                        <p *ngFor="let istituto of istitutiSelezionati " id="{{istituto.denominazioneIstituto}}"
                            (click)="selectIstitutoElement(istituto)"
                            [className]="istituto.selected ? 'element-select-istituti element-select-istituti-selected' : 'element-select-istituti'">
                            {{istituto.codScuola}} - {{istituto.denominazioneIstituto}}</p>
                    </div>
                </div>
            </div>

            <div class="row justify-content-start sezione-footer-autodichiarazione">
                <div class="col-12 col-md-4">
                    <p class="">Se la RSA di interesse non è presente, contattare l'help-desk ai riferimenti in
                        calce</p>
                </div>
                <div class="col-12 col-md-8" style="text-align: end;">
                    <button class="btn button-docenti " (click)="annullaAutoDichiarazione()">Annulla</button>
                    <button class="btn button-docenti " (click)="confermaAutoDichiarazione()">Conferma</button>
                </div>

            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>

<div *ngIf="modal_autodichiarazione.open" class="modale-background">
    <div class="modale-content">
        <div class="modal-dialog">
            <div class="modal-content modal-content-istruzioni">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Conferma operazione</h5>
                    <button type="button" class="close" (click)="modal_autodichiarazione.closeModal()"
                        data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body modal-content-istruzioni-content" id="istruzioni-content">
                    <p>Il/La sottoscritto/a <b>{{anagraficaDichiarante.nomeDichiarante}}
                            {{anagraficaDichiarante.cognomeDichiarante}}</b> </p>
                    <p>nato/a a <b>{{anagraficaDichiarante.comuneDichiarante}}</b> in provincia di
                        <b>{{anagraficaDichiarante.provinciaDichiarante}}</b>
                    </p>
                    <p> il <b>{{anagraficaDichiarante.dataNascitaDichiarante}}</b> </p>
                    <p>codice fiscale: <b>{{anagraficaDichiarante.codiceFiscaleDichiarante}}</b></p>
                    <p>Email: <b>{{anagraficaDichiarante.emailDichiarante}}</b></p>
                    <p>Telefono cellulare: <b>{{anagraficaDichiarante.cellDichiarante}}</b></p>
                    <p><b>Dichiaro</b> di essere il responsabile delle RSA selezionate nell'elenco sottostante</p>
                    <ul>
                        <li *ngFor="let istituto of istitutiSelezionati"> {{istituto.codScuola}} -
                            {{istituto.denominazioneIstituto}}</li>
                    </ul>
                    <p><b>Chiedo</b> di essere registrato ai fini dell'accesso al portale RSA Sicure per la
                        generazione dei codici per la prenotazione dei test di screening delle persone in RSA</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn button-docenti" (click)="modal_autodichiarazione.closeModal()"
                        data-dismiss="modal">Annulla</button>
                    <button type="button" class="btn button-docenti" (click)="censisciIstituto()"
                        data-dismiss="modal">Conferma</button>
                </div>
            </div>
        </div>
    </div>
</div>