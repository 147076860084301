import { Component, OnInit } from '@angular/core';
import { Utente } from './../../model/utente';
import { AuthService } from '../../service/auth/auth.service'
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common'
import { PersonaleScolasticoService } from './../../service/personale-scolastico.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IstitutoService } from './../../service/istituto.service';

@Component({
  selector: 'app-seleziona-istituto',
  templateUrl: './seleziona-istituto.component.html',
  styleUrls: ['./seleziona-istituto.component.css']
})
export class SelezionaIstitutoComponent implements OnInit {

  selectIstitutoForm: any;
  utente: Utente;
  istituto: any;
  istitutiList;
  istitutiListCodice;

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private personale: PersonaleScolasticoService,
    private istitutoService: IstitutoService,
    private location: Location) { }



  ngOnInit(): void {
    this.istitutiList = [];
    this.utente = this.auth.getUtente();
    this.spinner.show();
    this.istitutoService.getIstitutiCensimentoDirigente().subscribe(result => {
      this.spinner.hide();
      if (result.response) {
        /*  let item = { "codice": "RMO8746", "descrizione": "IIS TEST" }
         result.response.push(item); */
        this.auth.setFlagCambiaIstituto(true);
        let istituto = this.auth.getIstituto() ? this.auth.getIstituto().codice : result.response[0].codice;
        this.selectIstitutoForm = this.formBuilder.group({
          istituto: [istituto, [Validators.required]]
        });
        this.istitutiList = result.response;
      }
    },
      error => {
        this.spinner.hide();
      })
  }

  isCambiaIstituto() {
    let position = false;
    if (window.location.href.includes('cambia=true')) {
      position = true;
    }
    return position;
  }

  back(): void {
    this.location.back()
  }

  navigateTo(url) {
    this.router.navigate([url])
  }

  conferma() {
    let istituto = this.istitutiList.find(item => item.codice == this.selectIstitutoForm.value.istituto)
    this.auth.setIstituto(istituto)
    this.router.navigate(['/listaPrescrizioni'])
  }

}
