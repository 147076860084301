<app-header></app-header>
<div class="page-content">
    <div>
        <button type="button" (click)="navigateTo('/listaPersonale')" class="btn button-docenti">Gestione Persone RSA
        </button>
        <button type="button" (click)="navigateTo('/listaPrescrizioni')" class="btn button-prescrizione">Gestione Codice
            RSA</button>
    </div>
    <div class="sezione-titolo">
        <h3>Persone RSA - Dettaglio</h3>
    </div>

    <!-- form start   -->
    <form name='form' role="form" class="form" [formGroup]="censimentoCF"
        (ngSubmit)="cercaAnagrafica(censimentoCF.value)">
        <div class="row sezione-textEditor sezione-border">
            <div class="form-group col-12 col-md-6 align-items-center">
                <label class="control-label label">Codice Fiscale:(*) </label>
                <input class="form-control form-textEditor-cf form-control-sm" type="text" id='cf' formControlName="cf">
                <validation-messages [control]="censimentoCF.controls.cf"></validation-messages>
            </div>
            <div class="col-12 col-md-2 align-items-center">
                <div *ngIf="!modificaView" class="align-right">
                    <button class="btn button-docenti" type="submit">Recupera anagrafica</button>
                </div>
            </div>
        </div>
    </form>
    <!-- form -->

    <div class="sezione-anagrafica sezione-border">
        <div *ngFor="let property of campiAnagrafica" class="row">
            <p class="label-anagrafica">{{property.text}}:</p>
            <p class="">{{anagraficaDocente[property.value]}}</p>
        </div>
    </div>

    <form name='form' role="form" class="form-dati-docente" [formGroup]="datiDocente">
        <div class="row sezione-textEditor ">
            <div class="form-group col-12 col-md-5 align-items-center">
                <label class="control-label label">N° Cellulare: </label>
                <input class="form-control form-textEditor-cf form-control-sm" type="text" id='cell'
                    formControlName="cell">
                <validation-messages [control]="datiDocente.controls.cell"></validation-messages>
            </div>
        </div>
        <div class="row sezione-textEditor ">
            <div class="form-group col-12 col-md-5 align-items-center">
                <label class="control-label label">Email: </label>
                <input class="form-control form-textEditor-cf form-control-sm" type="text" id='email'
                    formControlName="email">
                <validation-messages [control]="datiDocente.controls.email"></validation-messages>
            </div>
        </div>
        <div class="row sezione-textEditor ">
            <div class="form-group col-12 col-md-5 align-items-center">
                <label class="control-label label">Ruolo:(*) </label>
                <select class="form-control form-textEditor-cf form-control-sm" type="select" id='ruolo'
                    formControlName="ruolo">
                    <option *ngFor="let ruolo of this.listaRuoli" value="{{ruolo.codOperatore}}">{{ruolo.descOperatore}}
                    </option>
                </select>
                <validation-messages [control]="datiDocente.controls.ruolo"></validation-messages>
            </div>
        </div>
    </form>

    <div class="row justify-content-end container-button-dettaglio">
        <div class="align-right">
            <button type="button" class="btn button-docenti" (click)="goToListaDocenti()">Indietro</button>
            <button *ngIf="!modificaView" class="btn button-docenti" (click)="pulisciCampi()">Pulisci campi</button>
            <button type="button" class="btn button-docenti" (click)="censisciDocente()">Salva</button>
        </div>
    </div>

</div>
<app-footer></app-footer>