import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Modal } from 'src/app/model/modal';
import { Utente } from 'src/app/model/utente';
import { ModalService } from '../../service/modale/modal.service';
import { AuthService } from '../../service/auth/auth.service'
import { TokenService } from './../../service/token/token.service'
import { UtilityService } from './../../utility/utility.service';
import * as moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  flagDisabilitaAccedi: Boolean;
  avvisoDisservizio: Boolean;

  public constructor(private titleService: Title,
    private router: Router,
    private auth: AuthService,
    private utility: UtilityService,
    private tokenService: TokenService,
    private modalService: ModalService
  ) { }

  public setTitle() {
    this.titleService.setTitle("RSA Sicure - Regione Toscana");
  }

  ngOnInit(): void {
    this.setTitle();
    this.flagDisabilitaAccedi = this.auth.getFlagDisabilitaAccedi();
    let dataOggi = moment();
    this.avvisoDisservizio = !this.controllaTemporizzazioneAvvisoDisservizio(dataOggi);

  }

  controllaTemporizzazioneAvvisoDisservizio(dataOggi): Boolean {
    let dataPredefinita = moment('02-11-2021 09:00', 'DD-MM-YYYY hh:mm');
    return dataOggi.isAfter(dataPredefinita);
  }

  logicaAvviso() {
    let dataOggi = moment();
    if (this.controllaTemporizzazionePaginaCortesia(dataOggi)) {
      this.router.navigate(["/disservizio"])
    } else {
      this.apriModaleAvviso(dataOggi);
    }
  }

  apriModaleAvviso(dataOggi) {
    if (!this.controllaTemporizzazioneAvvisoDisservizio(dataOggi)) {
      this.modalService.openModalGlobal("ATTENZIONE", "Il prossimo 30.10 dalle ore 18:00 alle ore 20:00 il servizio non sarà disponibile a causa di un intervento manutentivo.", "OK");
      sessionStorage.setItem("avvisoDisservizio", "true");
    }
  }

  controllaTemporizzazionePaginaCortesia(dataOggi): Boolean {
    let dataPredefinitaInizio = moment('30-10-2021 18:00', 'DD-MM-YYYY hh:mm');
    let dataPredefinitaFine = moment('30-10-2021 20:00', 'DD-MM-YYYY hh:mm');
    if (dataOggi.isAfter(dataPredefinitaInizio) && dataOggi.isBefore(dataPredefinitaFine)) {
      return true
    } else {
      return false
    }
  }

  accedi(): void {
    if (sessionStorage.getItem('access_token') === null) {
      this.tokenService.getAuthCode();
    } else if (sessionStorage.getItem("istituto")) {
      this.router.navigate(["/listaPrescrizioni"])
    } else {
      this.router.navigate(["/errorPage"])
    }
    //this.fakeLoginAbilitato();
    //this.fakeLoginNonAbilitato();
  }

  fakeLoginAbilitato() {
    let item = { "codice": "00134I", "descrizione": "FONDAZIONE TURATI I FIORI" }
    this.auth.setFlagCambiaIstituto(true);
    sessionStorage.setItem("istituto", JSON.stringify(item))
    sessionStorage.setItem("utente", JSON.stringify({ "lastName": "CESARE", "name": "GAIO GIULIO", "cf": "RDRLSE85C58D612T" }))
    this.router.navigate(["/listaPrescrizioni"])
  }

  fakeLoginNonAbilitato() {
    //let item = { "codice": "SIIS002001", "descrizione": "IIS E.S.PICCOLOMINI" }
    //sessionStorage.setItem("istituto", JSON.stringify(item))
    sessionStorage.setItem("access_token", "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJnZ3VFdlBBTS1rN0Jsa0xJMnN0VFN1Q0xVcXJsbDdwUE9JaWhsZUNFRWJZIn0.eyJqdGkiOiI0YTgzYzM5ZS1mNTg1LTQ3MTYtYmY0Mi0wNWNlNDM2N2MzZjYiLCJleHAiOjE2MTU1NTc0ODMsIm5iZiI6MCwiaWF0IjoxNjE1NTU3MTgzLCJpc3MiOiJodHRwczovL2FjY2Vzc29zaWN1cm8tdHJpYWwucmV0ZS50b3NjYW5hLml0L2F1dGgvcmVhbG1zL2FycGEiLCJzdWIiOiJmZjcwYWYxYy05ZjZkLTRmOGUtODNlZS00ZmVkYTQzNTRjY2UiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJjb3ZpZDE5LXNjdW9sZS1zaWN1cmUtYzEiLCJhdXRoX3RpbWUiOjE2MTU1NTcxODEsInNlc3Npb25fc3RhdGUiOiJiNmIwMzQ5NS05N2ZmLTQ3YmEtYjAzZS0yYTliNTRlNzJkYWMiLCJhY3IiOiIxIiwic2NvcGUiOiJwcm9maWxlIHJ0cm9sZXMgZGVmYXVsdCIsImZpc2NhbF9udW1iZXIiOiJUSU5JVC1GTFBDUFQ2OUE2NVozMzZQIiwiYXV0aF90eXBlIjoiRmVkZXJhdGlvbiBodHRwczovL2FycGFzdXBwb3J0LnRpeC5pdCIsImF1dGhfbGV2ZWwiOiIzIiwibmFtZSI6IkNMRU9QQVRSQSBURUEiLCJzcGlkX2NvZGUiOiIwN2U1ZTdhZC04NTVmLTRmNzMtYjdmMi1hMTIxZDA4YWQzMDQiLCJhdXRoVFMiOiIxNjE1NTU3MTgwNDQyIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiRkxQQ1BUNjlBNjVaMzM2UCIsImZhbWlseV9uYW1lIjoiRklMT1BBVE9SRSIsImF1dGhJRCI6IjAxX3Rlc3Q3XzE2MTU1NTcxODAzNDZfNTY5MTUiLCJydF9yb2xlcyI6WyJPcGVyYXRvcmUgRW50aSBBY2NyZWRpdGF0aSBTQ1IiLCJDaXR0YWRpbm8iXX0.VYMGxbDsTM9-VPrZct9fRo9f8TpSPYipmXdOkcqmh6LCDC8kg8JUIOYMrgFZ1aUJf5vCv8sKQhJ1JwJYBIv8V3OK2a_9S_Ve78-xhsAHPCUazNIFjWGcnS6OG16LbDJ2RfvuWkF2XfGBSMTI64JfsanvrXaR1PTtDDK9a8wCMJn7kiyKzuuRH8a0o7JYlCHwiFgmR4GLDUj5cCixUOsGoM3hMgNlx4A3q-zbC-00TbUjHD2uN0WSYAYj1BGKzRhC1dYXTMEbky-ThOMwYwEjKIglm_1T4XIjtk2IXMFdgXNkVLXNOXPTBpHA0TP1VHgCA2gQViV8dvotd78NI9PuSg")
    this.utility.setUtente(this.tokenService.decodeToken("eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJnZ3VFdlBBTS1rN0Jsa0xJMnN0VFN1Q0xVcXJsbDdwUE9JaWhsZUNFRWJZIn0.eyJqdGkiOiI0YTgzYzM5ZS1mNTg1LTQ3MTYtYmY0Mi0wNWNlNDM2N2MzZjYiLCJleHAiOjE2MTU1NTc0ODMsIm5iZiI6MCwiaWF0IjoxNjE1NTU3MTgzLCJpc3MiOiJodHRwczovL2FjY2Vzc29zaWN1cm8tdHJpYWwucmV0ZS50b3NjYW5hLml0L2F1dGgvcmVhbG1zL2FycGEiLCJzdWIiOiJmZjcwYWYxYy05ZjZkLTRmOGUtODNlZS00ZmVkYTQzNTRjY2UiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJjb3ZpZDE5LXNjdW9sZS1zaWN1cmUtYzEiLCJhdXRoX3RpbWUiOjE2MTU1NTcxODEsInNlc3Npb25fc3RhdGUiOiJiNmIwMzQ5NS05N2ZmLTQ3YmEtYjAzZS0yYTliNTRlNzJkYWMiLCJhY3IiOiIxIiwic2NvcGUiOiJwcm9maWxlIHJ0cm9sZXMgZGVmYXVsdCIsImZpc2NhbF9udW1iZXIiOiJUSU5JVC1GTFBDUFQ2OUE2NVozMzZQIiwiYXV0aF90eXBlIjoiRmVkZXJhdGlvbiBodHRwczovL2FycGFzdXBwb3J0LnRpeC5pdCIsImF1dGhfbGV2ZWwiOiIzIiwibmFtZSI6IkNMRU9QQVRSQSBURUEiLCJzcGlkX2NvZGUiOiIwN2U1ZTdhZC04NTVmLTRmNzMtYjdmMi1hMTIxZDA4YWQzMDQiLCJhdXRoVFMiOiIxNjE1NTU3MTgwNDQyIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiRkxQQ1BUNjlBNjVaMzM2UCIsImZhbWlseV9uYW1lIjoiRklMT1BBVE9SRSIsImF1dGhJRCI6IjAxX3Rlc3Q3XzE2MTU1NTcxODAzNDZfNTY5MTUiLCJydF9yb2xlcyI6WyJPcGVyYXRvcmUgRW50aSBBY2NyZWRpdGF0aSBTQ1IiLCJDaXR0YWRpbm8iXX0.VYMGxbDsTM9-VPrZct9fRo9f8TpSPYipmXdOkcqmh6LCDC8kg8JUIOYMrgFZ1aUJf5vCv8sKQhJ1JwJYBIv8V3OK2a_9S_Ve78-xhsAHPCUazNIFjWGcnS6OG16LbDJ2RfvuWkF2XfGBSMTI64JfsanvrXaR1PTtDDK9a8wCMJn7kiyKzuuRH8a0o7JYlCHwiFgmR4GLDUj5cCixUOsGoM3hMgNlx4A3q-zbC-00TbUjHD2uN0WSYAYj1BGKzRhC1dYXTMEbky-ThOMwYwEjKIglm_1T4XIjtk2IXMFdgXNkVLXNOXPTBpHA0TP1VHgCA2gQViV8dvotd78NI9PuSg"))
    //sessionStorage.setItem("utente", JSON.stringify({ "lastName": "CESARE", "name": "GAIO GIULIO", "cf": "CSRGGL44L13H501E" }))
    this.router.navigate(["/errorPage"])
  }

}
