export class Anagrafica {

    uidOperatore: string;
    cognome: string;
    nome: string;
    dataNascita: string;
    genere: string;
    comuneNascita: string;
    provinciaNascita: string;
    cfOperatore: string;


    getPropertyList() {
        return [
            { text: "Cognome", value: "cognome" },
            { text: "Nome", value: "nome" },
            { text: "Data di nascita", value: "dataNascita" },
            { text: "Genere", value: "genere" }
            //{ text: "Comune di nascita", value: "comuneNascita" },
            //{ text: "Provincia di nascita", value: "provinciaNascita" }
        ]
    }

}