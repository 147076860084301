import { Injectable } from '@angular/core';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'


@Injectable({
  providedIn: 'root'
})
export class PrintPdfService {

  constructor() { }

  static stampaTabella(istituto, data, elementID) {
    const doc = new jsPDF()
    let posXLabel = 15;
    let posXDati = 50;

    let istitutoTest = istituto.codice + "-" + istituto.descrizione;
    //doc.addImage(img, 'png', 60, 5, 90, 35)
    doc.setFontSize(24);
    //coordinate  (x,y)
    //dimensioni pagina x (0,205)

    doc.text("RSA Sicure", 82, 15);

    doc.setFontSize(18);
    doc.text("RSA: ", posXLabel, 30);
    doc.text("Data: ", posXLabel, 40);

    doc.setFontSize(14);
    doc.text(istitutoTest, posXDati, 30);
    doc.text(data, posXDati, 40);

    doc.text("Stampa codice RSA per le persone selezionate", posXLabel, 55);
    autoTable(doc,
      {
        html: elementID,
        startY: 60,
        theme: 'plain',
        /* styles: {
          lineColor: [255,255,255],
          lineWidth: 1,
        },*/
        headStyles: {
          fillColor: [255, 102, 102],
          textColor: [255, 255, 255],
        },/*
        footStyles: {
          fillColor: [0, 0, 0],
          fontSize: 20,
        },
        bodyStyles: {
          fillColor: [0, 0, 0],
        } */
      })
    doc.save('lista-codici-RSA-' + istitutoTest + '.pdf')
  }
}