export class IstitutoElement {

    codScuola: string;
    codTipoIstituto: string;
    denominazioneIstituto: string;
    denominazioneProvincia: string;

    selected: boolean;

    constructor(istitutoElement) {
        this.codScuola = istitutoElement.codScuola;
        this.codTipoIstituto = istitutoElement.codTipoIstituto;
        this.denominazioneProvincia = istitutoElement.denominazioneProvincia;
        this.denominazioneIstituto = istitutoElement.denominazioneIstituto;
        this.selected = false;
    }

}