import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Costanti } from './../../utility/costanti';
import { Docente } from './../../model/docente';
import { CsvOperatori } from '../../model/csvOperatori';
import { PropertyTable } from './../../model/propertyTable';
import { AuthService } from '../../service/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValidationService } from './../../service/form/validation.service'
import { PersonaleScolasticoService } from './../../service/personale-scolastico.service'
import { ModalService } from './../../service/modale/modal.service'
import { ViewChild } from '@angular/core';
import { PopoverModule } from "ngx-smart-popover";

import { Modal } from './../../model/modal';
import * as moment from 'moment';


@Component({
  selector: 'app-lista-docenti',
  templateUrl: './lista-docenti.component.html',
  styleUrls: ['./lista-docenti.component.css']
})
export class ListaDocentiComponent implements OnInit {

  @ViewChild('inputCsv')
  myInputVariable: ElementRef;


  ricercaForm: any;
  uploadCsvForm: any;
  modal_istruzioni: Modal;

  headerList: PropertyTable[]
  visualizzaElementiList: string[];
  propertyList: PropertyTable[];
  docentiList: Docente[];
  showPersonaleScolasticoList: Boolean;
  modale_elimina: Modal;
  modale_caricamento_csv: Modal;
  page: number;
  elements: number;
  orderBy: String;
  orderDirection: String;
  totalElements: number;
  totalPage: number;
  headerListTabellaUploadCsv: PropertyTable[];
  propertyListTabellaUploadCsv: PropertyTable[];
  csvList: any;
  uploadFileSuccess: Boolean;
  csvUploaded: any;
  clicked: Boolean;


  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private router: Router,
    private auth: AuthService,
    private personaleScolasticoService: PersonaleScolasticoService
  ) {
    if (this.auth.getFiltriDocenteList()) {
      this.ricercaForm = this.formBuilder.group({
        cognome: [this.auth.getFiltriDocenteList().cognome],
        nome: [this.auth.getFiltriDocenteList().nome],
        cf: [this.auth.getFiltriDocenteList().cf]
      });
    } else {
      this.ricercaForm = this.formBuilder.group({
        cognome: [''],
        nome: [''],
        cf: ['']
      });
    }
  }

  ngOnInit(): void {
    moment.locale("it")
    this.page = 1;
    this.elements = Costanti.ELEMENT_DEFAULT_OPTION;
    this.uploadFileSuccess = false;
    this.modale_elimina = new Modal();
    this.modal_istruzioni = new Modal();
    this.modale_caricamento_csv = new Modal();
    this.clicked = false;
    this.auth.setDocente(null)
    this.headerList = Costanti.HEADER_DOCENTI;
    this.propertyList = Costanti.PROPERTY_DOCENTI;
    this.headerListTabellaUploadCsv = Costanti.HEADER_CSV_UPLOAD_TABLE;
    this.propertyListTabellaUploadCsv = Costanti.PROPERTY_CSV_UPLOAD_TABLE;
    this.visualizzaElementiList = Costanti.ELEMENT_SELECT_OPTION;
    this.orderBy = Costanti.ORDERBY_PERSONALE_DEFAULT;
    this.orderDirection = Costanti.ORDERDIRECTION_PERSONALE_DEFAULT;
    this.csvList = [];
    this.getDocenti();
  }

  navigateTo(url) {
    this.router.navigate([url])
  }

  cerca() {
    this.getDocenti();
  }

  getDocenti(): void {
    this.spinner.show();
    this.showPersonaleScolasticoList = false;
    let parametriRicercaForm = this.ricercaForm.value;
    let params = {
      codiceScuola: this.auth.getCodiceScuola(),
      cognome: parametriRicercaForm.cognome,
      nome: parametriRicercaForm.nome,
      cf: parametriRicercaForm.cf,
      page: this.page,
      elements: this.elements,
      orderBy: this.orderBy,
      orderDirection: this.orderDirection
    }
    this.personaleScolasticoService.getPersonaleScolastico(params).subscribe(
      result => {
        this.spinner.hide();
        if (result.response) {
          this.totalElements = result.response.totalElements;
          this.totalPage = result.response.totalPage;
          this.docentiList = result.response.content.map(item => {
            return new Docente(item);
          });

          this.showPersonaleScolasticoList = true;
        }
      },
      error => {
        this.spinner.hide();
        this.modalService.openModalGlobal("Attenzione", "Errore", "ok")

      }
    )
  }

  modifica(docente) {
    this.auth.setFiltriDocenteList(this.ricercaForm.value)
    this.auth.setDocente(docente);
    this.navigateTo("/dettaglio-persone")
  }

  aggiungiDocente() {
    this.navigateTo("/dettaglio-persone")
  }

  elimina(docente) {
    this.modale_elimina.openModal("ATTENZIONE", "Confermi l'eliminazione?")
    this.modale_elimina.personale = docente;
  }

  eliminaPersonale(docente) {
    this.modale_elimina.closeModal();
    let params = {
      cfOperatore: docente.cfOperatore,
      codiceScuola: this.auth.getCodiceScuola()
    }
    this.spinner.show();
    this.personaleScolasticoService.deletePersonaleScolastico(params).subscribe(
      result => {
        this.getDocenti();
      },
      error => {
        this.spinner.hide();
        this.modalService.openModalGlobal("Attenzione", "Errore", "ok")
      }
    )
  }

  richiesta(docente) {
    this.auth.setDocente(docente);
    this.navigateTo("/listaPrescrizioni")
  }

  setOrdinamento(header) {
    if (header.orderDirection == "DESC") {
      header.orderDirection = "ASC";
    } else {
      header.orderDirection = "DESC";
    }
    this.orderBy = header.orderBy;
    this.orderDirection = header.orderDirection;
    this.getDocenti();

  }

  opendModalIstruzioni() {
    this.modal_istruzioni.openModal("", "");
  }

  changeTotalElements() {
    this.page = 1;
    this.getDocenti()
  }

  calcolaPrimoValoreElementiVisualizzati(): number {
    return this.totalElements > 0 ? this.elements * (this.page - 1) + 1 : 0;
  }

  calcolaSecondoValoreElementiVisualizzati(): number {
    return (this.elements * (this.page)) > this.totalElements ? this.totalElements : (this.elements * (this.page))
  }


  increasePage() {
    if (this.page != this.totalPage && this.totalElements > 0) {
      this.page++;
      this.getDocenti();
    }
  }

  setPage(numero) {
    this.page = numero;
    this.getDocenti();
  }

  decreasePage() {
    if (this.page != 1) {
      this.page -= 1;
      this.getDocenti();
    }
  }

  gestisciFile(value) {
    this.csvUploaded = value;
    this.uploadFileSuccess = false;
  }

  rimuoviFileSelect(value) {
    this.myInputVariable.nativeElement.value = "";

  }

  removeFile() {
    this.csvUploaded = undefined;
  }

  scaricaTemplate() {
    let link = document.createElement("a");
    link.href = "./assets/documents/Personale_Esempio.csv";
    link.click();
  }

  closeModaleUpload() {
    this.uploadFileSuccess = false;
    this.removeFile();
    this.modale_caricamento_csv.closeModal()
    this.getDocenti();
  }
  uploadCsv() {
    if (this.csvUploaded) {
      let params = {
        codiceScuola: this.auth.getCodiceScuola(),
        csvFile: this.csvUploaded
      }
      this.spinner.show();
      this.personaleScolasticoService.uploadDocentiCsv(params).subscribe(
        response => {
          this.spinner.hide();
          if (response.message.level == "ERROR") {
            this.modalService.openModalGlobal("Attenzione", response.message.description, "ok")
          } else {
            this.removeFile();
            this.uploadFileSuccess = true;
            this.recuperaListaFileCsv()
          }
        },
        error => {
          this.spinner.hide();
          this.modalService.openModalGlobal("Attenzione", "Errore", "ok")

        });
    } else {
      this.modalService.openModalGlobal("Attenzione", "E' necessario specificare il file da caricare tramite il pulsante 'Scegli file'", "ok")
    }
  }

  pulisciCampi() {
    this.ricercaForm.reset();
    this.auth.setFiltriDocenteList("")
    this.getDocenti();
  }

  caricamentoDaCsv() {
    this.modale_caricamento_csv.openModal("Caricamento csv Persone in RSA ", "")
    this.recuperaListaFileCsv();
  }

  recuperaListaFileCsv() {
    let params = {
      codiceScuola: this.auth.getCodiceScuola()
    }
    this.spinner.show();
    this.personaleScolasticoService.fileCaricatiList(params).subscribe(
      result => {
        this.spinner.hide();
        this.csvList = result.response.map(item => {
          return new CsvOperatori(item);
        });
        this.setIsScarti(this.csvList);
      },
      error => {
        this.spinner.hide();
        this.modalService.openModalGlobal("Attenzione", "Errore", "ok")

      });
  }



  base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  downloadFile(response) {

    var file = this.base64ToArrayBuffer(response.file);
    var newBlob = new Blob([file], { type: response.mediaType })

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = response.nomeFile + response.estensione;
    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  setIsScarti(csvList) {
    let boolean = false;
    for (let csv of csvList) {
      let params = {
        codiceScuola: this.auth.getCodiceScuola(),
        idFile: csv.id
      }
      this.spinner.show();
      this.personaleScolasticoService.scartiDisponibili(params).subscribe(
        result => {
          this.spinner.hide();
          if (result.response) {
            csv.isScarti = result.response
          }
        },
        error => {
          this.spinner.hide();
        });
    }
  }


  scartiCsv(csv) {
    let params = {
      codiceScuola: this.auth.getCodiceScuola(),
      idFile: csv.id
    }
    this.spinner.show();
    this.personaleScolasticoService.scartiCsv(params).subscribe(
      result => {
        this.spinner.hide();
        this.downloadFile(result.response)
      },
      error => {
        this.spinner.hide();
        this.modalService.openModalGlobal("Attenzione", "Errore", "ok")

      });
  }

}



