import * as moment from 'moment';

export class CsvOperatori {

    private id: string;
    private dataCaricamento: string;
    private utenteCreazione: string;
    private dataFineElaborazione: string;
    private statoElaborazione;
    private statoElaborazioneDescr: string;
    private nomeFile: string;
    isScarti: Boolean;


    constructor(csvoperatori) {
        moment.locale("it");

        this.id = csvoperatori.id;
        this.dataCaricamento = csvoperatori.dataCaricamento ? moment(csvoperatori.dataCaricamento).format("L") + " " + moment(csvoperatori.dataCaricamento).format("LTS") : "-";
        this.utenteCreazione = csvoperatori.utenteCreazione;
        this.dataFineElaborazione = csvoperatori.dataFineElaborazione ? moment(csvoperatori.dataFineElaborazione).format("L") + " " + moment(csvoperatori.dataFineElaborazione).format("LTS") : "-";
        this.statoElaborazione = csvoperatori.statoElaborazione;
        this.statoElaborazioneDescr = csvoperatori.statoElaborazione.descrizione;
        this.nomeFile = csvoperatori.nomeFile;
        this.isScarti = false;

    }

}