<div class="wrapper">
    <app-header></app-header>
    <div class="justify-content-center page-content">
        <div class="home-content">
            <h3>RSA Sicure - Seleziona RSA</h3>
            <div class="sezione-seleziona-istituto">
                <h4 class="sezione-nome-utente">
                    {{utente.name}} {{utente.lastName}}
                </h4>
                <form *ngIf="istitutiList.length > 0" class="row col-12 col-md-12 align-items-center" name='form'
                    role="form" class="form-dati-docente" [formGroup]="selectIstitutoForm">
                    <label class=" col-12 col-md-5 label">Seleziona RSA: </label>
                    <select class="form-control-sm select-istituto col-12 col-md-7" type="select" id='istituto'
                        formControlName="istituto">
                        <option *ngFor="let istituto of istitutiList" value="{{istituto.codice}}">{{istituto.codice}} -
                            {{istituto.descrizione}}</option>
                    </select>
                </form>
                <div class="row justify-content-center sezione-pulsanti-seleziona-istituto">
                    <button *ngIf="istitutiList.length > 0" type="button" (click)="conferma()"
                        class="btn button-docenti col-12 col-md-5">Conferma</button>
                    <button *ngIf="isCambiaIstituto()" type="button" (click)="back()"
                        class="btn button-docenti col-12 col-md-5">Annulla</button>
                </div>
            </div>

        </div>
    </div>
    <app-footer></app-footer>

</div>