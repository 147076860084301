    <div class="maxSizeContent container-breadcrumb">
        <div *ngFor="let item of breadcrumbsList" class="sezione-breadcrumb">
            <div class="breadCrumb">
                <div id="button"  [className]="item.active ? 'active_button btn rounded' : 'btn rounded'">
                    <span class="number_button">{{item.id}}</span>
                </div>
                <span class="text_button">{{item.text}}</span>
            </div>
            <div *ngIf="item.separator" class="separatore"></div>
        </div>
    </div>
