export class Costanti {
  public static BREADCRUMBS_LIST = [
    { id: 1, text: "Accedi", separator: true, active: true },
    { id: 2, text: "Seleziona", separator: true, active: false },
    { id: 3, text: "Richiedi", separator: false, active: false }
  ];
  public static HEADER_PRESCRIZIONI = [
    { value: "Cognome", align: "left", orderDirection: "ASC", orderBy: "an.cognome" },
    { value: "Nome", align: "left", orderDirection: "ASC", orderBy: "an.nome" },
    { value: "Codice Fiscale", align: "left", orderDirection: "ASC", orderBy: "an.cod_fiscale" },
    { value: "Data Nascita", align: "left", orderDirection: "ASC", orderBy: "an.DATA_NASCITA" },
    { value: "N°Cellulare", align: "left", orderDirection: "ASC", orderBy: "os.telefono" },
    { value: "Email", align: "left", orderDirection: "ASC", orderBy: "os.email" },
    { value: "Ruolo", align: "left", orderDirection: "ASC", orderBy: "tpo.DESC_OPERATORE" },
    { value: "Codice RSA", align: "left", orderDirection: "ASC", orderBy: "po.NRE" },
    { value: "Stato", align: "left" },
    { value: "RICHIEDI", align: "center" },
    { value: "STAMPA", align: "center" },
    { value: "STORICO RICHIESTE", align: "center" },
  ];
  public static PROPERTY_PRESCRIZIONI = [
    { value: "cognome", align: "left" },
    { value: "nome", align: "left" },
    { value: "cfOperatore", align: "left" },
    { value: "dataNascita", align: "left" },
    { value: "telefono", align: "left" },
    { value: "email", align: "left" },
    { value: "descOperatore", align: "left" },
    { value: "nreT", align: "left" },
    { value: "stato", align: "left" }
  ];
  public static HEADER_DOCENTI = [
    { value: "Cognome", align: "left", orderDirection: "ASC", orderBy: "an.cognome" },
    { value: "Nome", align: "left", orderDirection: "ASC", orderBy: "an.nome" },
    { value: "Codice Fiscale", align: "left", orderDirection: "ASC", orderBy: "an.cod_fiscale" },
    { value: "Data Nascita", align: "left", orderDirection: "ASC", orderBy: "an.DATA_NASCITA" },
    { value: "N°Cellulare", align: "left", orderDirection: "ASC", orderBy: "os.telefono" },
    { value: "Email", align: "left", orderDirection: "ASC", orderBy: "os.email" },
    { value: "Ruolo", align: "left", orderDirection: "ASC", orderBy: "tpo.DESC_OPERATORE" },
    { value: "Modifica", align: "center" },
    { value: "Elimina", align: "center" },
    { value: "Richieste", align: "center" }
  ];
  public static PROPERTY_DOCENTI = [
    { value: "cognome", align: "left" },
    { value: "nome", align: "left" },
    { value: "cfOperatore", align: "left" },
    { value: "dataNascita", align: "left" },
    { value: "telefono", align: "left" },
    { value: "email", align: "left" },
    { value: "descOperatore", align: "left" },
  ];
  public static HEADER_STAMPA_TABLE = [
    { value: "Cognome", align: "left" },
    { value: "Nome", align: "left" },
    { value: "Codice Fiscale", align: "left" },
    { value: "Codice RSA", align: "left" },
    { value: "Stato", align: "left" },
  ];
  public static PROPERTY_STAMPA_TABLE = [
    { value: "cognome", align: "left" },
    { value: "nome", align: "left" },
    { value: "cfOperatore", align: "left" },
    { value: "nreT", align: "left" },
    { value: "stato", align: "left" },
  ];
  public static HEADER_CSV_UPLOAD_TABLE = [
    { value: "#", align: "left" },
    { value: "Nome File", align: "left" },
    { value: "Data Caricamento", align: "left" },
/*     { value: "Utente Caricamento", align: "left" },
 */    { value: "Data fine Elaborazione", align: "left" },
    { value: "Stato Elaborazione", align: "left" },
    { value: "Scarica errori csv", align: "center" },
  ];

  public static PROPERTY_CSV_UPLOAD_TABLE = [
    { value: "id", align: "left" },
    { value: "nomeFile", align: "left" },
    { value: "dataCaricamento", align: "left" },
/*     { value: "utenteCreazione", align: "left" },
 */    { value: "dataFineElaborazione", align: "left" },
    { value: "statoElaborazioneDescr", align: "left" },
  ];
  public static HEADER_STORICO_TABLE = [
    { value: "Codice RSA", align: "left" },
    { value: "Data Creazione", align: "left" },
    { value: "Data Prenotazione", align: "left" },
  ];
  public static PROPERTY_STORICO_TABLE = [
    { value: "nreT", align: "left" },
    { value: "dataCreazione", align: "left" },
    { value: "dataPrenotazione", align: "left" },
  ];
  public static ELEMENT_DEFAULT_OPTION = 50;
  public static ELEMENT_SELECT_OPTION = ["5", "10", "25", "50"];

  public static ORDERBY_PRESCRIZIONI_DEFAULT = "an.cognome";
  public static ORDERDIRECTION_PRESCRIZIONI_DEFAULT = "ASC";

  public static ORDERBY_PERSONALE_DEFAULT = "an.cognome";
  public static ORDERDIRECTION_PERSONALE_DEFAULT = "ASC";

  public static ERROR_MESSAGES_FORM = {
    required: "Obbligatorio",
    invalidCF: "Codice Fiscale non valido",
    invalidEmail: "Email non valida",
    invalidPhone: "Numero non valido"
  }

}
