<div class="wrapper">
    <app-header></app-header>
    <div class="justify-content-center page-content">
        <div style="width: 50%; margin: auto; margin-top: 50px;text-align:center">
            <h2>UTENTE NON ABILITATO ALL' ACCESSO</h2>
        </div>
        <!--         <div class="alert alert-info alert-box text_2 home-content">
            <p>
                Non sei stato riconosciuto come responsabile di una RSA.
                Se hai questo ruolo ed hai chiesto l'accesso per la generazione dei codici RSA per la prenotazione
                dei test
                di screening delle persone in RSA di
                tua competenza procedi con l'autodichiarazione
            </p>
        </div>
        <div style="width: 50%; margin: auto; margin-top: 50px; text-align:center">
            <button class="btn button-docenti" (click)="getautoDichiarazione()"
                [disabled]="autodichiarazionePresente">Procedi per Autodichiarazione </button>
        </div> -->
    </div>
    <app-footer></app-footer>
</div>