import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PrescrizioniService {

  urlSisrsaSicureBe: string;

  constructor(private http: HttpClient) {
    this.urlSisrsaSicureBe = environment.sis_rsa_sicure_be + "/richieste";
  }

  setHeaders() {
    return {
      httpOptions: {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
        })
      }
    }
  }

  getPrescrizioni(params) {
    const options = this.setHeaders();

    let prescrizione_no = (params.prescrizione_no != null) ? params.prescrizione_no : 'false';
    let prescrizione_si = (params.prescrizione_si != null) ? params.prescrizione_si : 'false';
    //let prenotazione_si = (params.prescrizione_si != null) ? params.prescrizione_si : 'false';
    //let erogato_si = (params.prescrizione_si != null) ? params.prescrizione_si : 'false';

    let prenotazione_si = (params.prenotazione_si != null) ? params.prenotazione_si : 'false';
    let erogato_si = (params.erogato_si != null) ? params.erogato_si : 'false';


    let queryParams = "?";
    queryParams += params.codiceScuola ? "codiceScuola=" + params.codiceScuola : "";
    queryParams += params.cognome ? "&cognome=" + params.cognome : "";
    queryParams += params.nome ? "&nome=" + params.nome : "";
    queryParams += params.cf ? "&cf=" + params.cf : "";

    queryParams += "&prescrizione_no=" + prescrizione_no;
    queryParams += "&prescrizione_si=" + prescrizione_si;
    queryParams += "&prenotazione_si=" + prenotazione_si;
    queryParams += "&erogato_si=" + erogato_si;
    queryParams += "&page=" + params.page;
    queryParams += "&elements=" + params.elements;
    queryParams += "&orderBy=" + params.orderBy;
    queryParams += "&orderDirection=" + params.orderDirection;
    return this.http.get<any>(this.urlSisrsaSicureBe + queryParams, options.httpOptions);
  }

  getStorico(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.codiceScuola ? "codiceScuola=" + params.codiceScuola : "";
    queryParams += params.cf ? "&cf=" + params.cf : "";
    return this.http.get<any>(this.urlSisrsaSicureBe + "/storico" + queryParams, options.httpOptions);
  }

  sendEmail(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.nre ? "nre=" + params.nre : "";
    queryParams += params.cf ? "&cf=" + params.cf : "";
    queryParams += params.email ? "&email=" + params.email : "";
    return this.http.get<any>(this.urlSisrsaSicureBe + "/inviaMail" + queryParams, options.httpOptions);
  }

  richiediNre(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.codiceScuola ? "codiceScuola=" + params.codiceScuola : "";
    return this.http.put<any>(this.urlSisrsaSicureBe + "/richiedi" + queryParams, params.cf, options.httpOptions);
  }

}
