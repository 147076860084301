import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PersonaleScolasticoService {

  urlSisrsaSicureBe: string;

  constructor(private http: HttpClient) {
    this.urlSisrsaSicureBe = environment.sis_rsa_sicure_be + "/personale";
  }

  setHeaders() {
    return {
      httpOptions: {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
        })
      }
    }
  }

  dettaglioPersonale(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.codiceScuola ? "codiceScuola=" + params.codiceScuola : "";
    queryParams += params.cfOperatore ? "&cf=" + params.cfOperatore : "";
    return this.http.get<any>(this.urlSisrsaSicureBe + "/dettaglioDocente" + queryParams, options.httpOptions);
  }

  getIstituti() {
    const options = this.setHeaders();
    return this.http.get<any>(environment.sis_rsa_sicure_be + "/richieste/istituti", options.httpOptions);
  }

  getPersonaleScolastico(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.codiceScuola ? "codiceScuola=" + params.codiceScuola : "";
    queryParams += params.cognome ? "&cognome=" + params.cognome : "";
    queryParams += params.nome ? "&nome=" + params.nome : "";
    queryParams += params.cf ? "&cf=" + params.cf : "";
    queryParams += "&page=" + params.page;
    queryParams += "&elements=" + params.elements;
    queryParams += "&orderBy=" + params.orderBy;
    queryParams += "&orderDirection=" + params.orderDirection;
    return this.http.get<any>(this.urlSisrsaSicureBe + queryParams, options.httpOptions);
  }

  deletePersonaleScolastico(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.codiceScuola ? "codiceScuola=" + params.codiceScuola : "";
    queryParams += params.cfOperatore ? "&cf=" + params.cfOperatore : "";
    return this.http.put<any>(this.urlSisrsaSicureBe + "/delete" + queryParams, '', options.httpOptions);

  }

  savePersonaleScolastico(params, body) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.codiceScuola ? "codiceScuola=" + params.codiceScuola : "";
    return this.http.post<any>(this.urlSisrsaSicureBe + "/salvaDocente" + queryParams, body, options.httpOptions);
  }

  getAnagraficaPersonaleCensito(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.codiceScuola ? "codiceScuola=" + params.codiceScuola : "";
    queryParams += params.cfOperatore ? "&cf=" + params.cfOperatore : "";
    return this.http.get<any>(this.urlSisrsaSicureBe + "/dettaglioDocente" + queryParams, options.httpOptions);
  }

  uploadDocentiCsv(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.codiceScuola ? "codiceScuola=" + params.codiceScuola : "";
    var formData = new FormData();
    formData.append("csvFile", params.csvFile)
    return this.http.post<any>(this.urlSisrsaSicureBe + "/uploadDocentiCsv" + queryParams, formData, options.httpOptions);
  }

  scartiCsv(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.idFile ? "idFile=" + params.idFile : "";
    queryParams += params.codiceScuola ? "&codiceScuola=" + params.codiceScuola : "";
    return this.http.get<any>(this.urlSisrsaSicureBe + "/scarti" + queryParams, options.httpOptions);
  }

  scartiDisponibili(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.idFile ? "idFile=" + params.idFile : "";
    queryParams += params.codiceScuola ? "&codiceScuola=" + params.codiceScuola : "";
    return this.http.get<any>(this.urlSisrsaSicureBe + "/scartiDisponibili" + queryParams, options.httpOptions);
  }

  fileCaricatiList(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.codiceScuola ? "&codiceScuola=" + params.codiceScuola : "";
    return this.http.get<any>(this.urlSisrsaSicureBe + "/fileCaricati" + queryParams, options.httpOptions);
  }

  ruoliOperatore(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.codiceScuola ? "&codiceScuola=" + params.codiceScuola : "";
    return this.http.get<any>(this.urlSisrsaSicureBe + "/tipoOperatore" + queryParams, options.httpOptions);
  }

}
