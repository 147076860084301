import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utente } from 'src/app/model/utente';
import { AuthService } from './../../service/auth/auth.service';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  home: boolean;
  utente: Utente;
  istituto: any;
  flagCambiaIstituto: Boolean;
  prescrizioniPage: Boolean;
  cittadino: Boolean;


  constructor(
    private router: Router,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.prescrizioniPage = this.isPrescrizioni();
    this.utente = this.auth.getUtente();
    this.istituto = this.auth.getIstituto();
    this.home = this.isHome();
    this.cittadino = this.isCittadino();
    this.flagCambiaIstituto = this.auth.getFlagCambiaIstituto();
  }

  isPrescrizioni(): boolean {
    return window.location.href.includes('listaPrescrizioni');
  }

  isHome(): boolean {
    let position = false;
    if (window.location.href.includes('home')
      || (window.location.href.includes('disservizio'))
    ) {
      position = true;
    }
    return position;
  }

  isCittadino(): boolean {
    let position = false;
    if (window.location.href.includes('dichiarazione-sostitutiva')
      || (window.location.href.includes('errorPage')
      )
    ) {
      position = true;
    }
    return position;
  }

  logout() {
    this.auth.deleteSession()
  }

  cambiaIstituto() {
    this.router.navigate(['/seleziona-rsa'], { queryParams: { cambia: true } });
  }

}
