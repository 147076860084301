import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthService } from './../../service/auth/auth.service';
import { ValidationService } from './../../service/form/validation.service';
import { ModalService } from './../../service/modale/modal.service';
import { IstitutoElement } from './../../model/istitutoElement';
import { IstitutoService } from './../../service/istituto.service';
import { Modal } from './../../model/modal';
import { ReplaySubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';

@Component({
  selector: 'app-dichiarazione-sostitutiva',
  templateUrl: './dichiarazione-sostitutiva.component.html',
  styleUrls: ['./dichiarazione-sostitutiva.component.css']
})
export class DichiarazioneSostitutivaComponent implements OnInit {
  consensoForm: any;
  ricercaIstitutiForm: any;
  anagraficaDichiaranteForm: any;

  listaProvince: any;
  listaComuni: any;
  listaTipoOrganismoScolastico: any;
  listaTipoIstituto: any;
  codiceFiscaleDichiarante: any;
  noValidForm: Boolean;

  anagraficaDichiarante: any;
  utente: any;
  modal_autodichiarazione: Modal;
  istitutiNonSelezionati: any;
  istitutiSelezionati: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private istitutoService: IstitutoService
  ) {
    this.modal_autodichiarazione = new Modal();
    this.istitutiNonSelezionati = [];
    this.istitutiSelezionati = [];
    this.noValidForm = false;
    this.utente = this.auth.getUtente();
    this.codiceFiscaleDichiarante = this.utente.cf;

  }

  ngOnInit(): void {
    this.inizializzaForm();
    this.inizializzaComboBox();
  }

  orderByCodiceScuola(lista) {
    lista.sort(function (a, b) {
      if (a.codScuola < b.codScuola) {
        return -1;
      }
    });
    return lista;
  }



  inizializzaForm() {
    this.inizializzaCheckBoxConsensi();
    this.inizializzaRicercaIstitutoForm();
    this.inizializzaAnagraficaDichiarante();
    //questo è il blocco per la redirect se vai direttamente sulla url
    this.getautoDichiarazione();
  }

  inizializzaCheckBoxConsensi() {
    this.consensoForm = this.formBuilder.group({
      dichiaro: [false, [ValidationService.mustbeTrue]],
      chiedo: [false, [ValidationService.mustbeTrue]],
    });
  }

  inizializzaAnagraficaDichiarante() {
    this.anagraficaDichiaranteForm = this.formBuilder.group({
      nomeDichiarante: [this.utente.name],
      cognomeDichiarante: [this.utente.lastName],
      comuneDichiarante: ['', [Validators.required]],
      provinciaDichiarante: ['', [Validators.required, ValidationService.province]],
      dataNascitaDichiarante: ['', [Validators.required, ValidationService.validateData]],
      emailDichiarante: ['', [Validators.required, ValidationService.validateEmail]],
      confermaEmailDichiarante: ['', [Validators.required, ValidationService.validateEmail]],
      cellDichiarante: ['', [Validators.required, ValidationService.validatePhone]]
    });
    this.anagraficaDichiaranteForm.get("nomeDichiarante").disable();
    this.anagraficaDichiaranteForm.get("cognomeDichiarante").disable();
  }

  inizializzaRicercaIstitutoForm() {
    this.ricercaIstitutiForm = this.formBuilder.group({
      tipoIstituto: 1,
      provincia: '',
      denominazioneIstituto: '',
      organismoScolastico: '',
      comune: ''
    });
  }

  inizializzaComboBox() {
    this.getIstitutiCensimento();
    this.getIstitutiCensimentoProvincia();
    this.getIstitutiCensimentoComune();
    this.getIstitutiCensimentoTipoOrganismoScolastico();
    this.getTipoIstituto();
  }

  getIstitutiCensimento() {
    let params = {
      codTipoIstituto: this.ricercaIstitutiForm.value.tipoIstituto,
      provincia: this.ricercaIstitutiForm.value.provincia,
      denominazioneIstituto: this.ricercaIstitutiForm.value.denominazioneIstituto,
      comune: this.ricercaIstitutiForm.value.comune,
      organismoScolastico: this.ricercaIstitutiForm.value.organismoScolastico,
    }
    this.spinner.show();
    this.istitutoService.getIstitutiCensimento(params).subscribe(result => {
      this.spinner.hide();
      this.istitutiNonSelezionati = [];
      for (let istituto of result.response) {
        this.istitutiNonSelezionati.push(new IstitutoElement(istituto))
      }
      this.istitutiNonSelezionati = this.orderByCodiceScuola(this.istitutiNonSelezionati);
    },
      err => {
        this.spinner.hide();
        this.modalService.openModalGlobal("ATTENZIONE", "Il servizio non è al momento disponibile, riprovare tra qualche minuto", "Ok");
      })
  }

  getautoDichiarazione() {
    this.istitutoService.getAutodichiarazione().subscribe(result => {
      if (result.response != null) {
        this.modalService.openModalGlobal("ATTENZIONE", "Autodichiarazione già depositata", "Ok", () => this.goToHomeAndLogout(), () => this.goToHomeAndLogout());
      }
    },
      err => {
        this.modalService.openModalGlobal("ATTENZIONE", "Il servizio non è al momento disponibile, riprovare tra qualche minuto", "Ok");
      })
  }

  /*   getIstitutiCensimentoDirigente() {
      this.istitutoService.getIstitutiCensimentoDirigente().subscribe(result => {
      })
    }
   */

  getIstitutiCensimentoProvincia() {
    this.istitutoService.getIstitutiCensimentoProvincia().subscribe(result => {
      this.listaProvince = result.response;
    },
      err => {
        this.spinner.hide();
        this.modalService.openModalGlobal("ATTENZIONE", "Il servizio non è al momento disponibile, riprovare tra qualche minuto", "Ok");
      })
  }

  getIstitutiCensimentoComune() {
    if (this.ricercaIstitutiForm.value.provincia != '') {
      let params = {
        provincia: this.ricercaIstitutiForm.value.provincia,
      }
      this.istitutoService.getIstitutiCensimentoComune(params).subscribe(result => {
        this.listaComuni = result.response;
        this.ricercaIstitutiForm = this.formBuilder.group({
          tipoIstituto: 1,
          provincia: this.ricercaIstitutiForm.value.provincia,
          denominazioneIstituto: this.ricercaIstitutiForm.value.denominazioneIstituto,
          organismoScolastico: this.ricercaIstitutiForm.value.organismoScolastico,
          comune: ''
        });
        this.getIstitutiCensimento();
      })
    } else {
      this.listaComuni = [];
      this.ricercaIstitutiForm = this.formBuilder.group({
        tipoIstituto: 1,
        provincia: this.ricercaIstitutiForm.value.provincia,
        denominazioneIstituto: this.ricercaIstitutiForm.value.denominazioneIstituto,
        organismoScolastico: this.ricercaIstitutiForm.value.organismoScolastico,
        comune: ''
      });
      this.getIstitutiCensimento();
    }
  }

  getIstitutiCensimentoTipoOrganismoScolastico() {
    this.istitutoService.getIstitutiCensimentoTipoOrganismoScolastico().subscribe(result => {
      this.listaTipoOrganismoScolastico = result.response;
    })
  }

  getTipoIstituto() {
    this.istitutoService.getTipoIstituto().subscribe(result => {
      this.listaTipoIstituto = result.response;
    },
      err => {
        this.spinner.hide();
        this.modalService.openModalGlobal("ATTENZIONE", "Il servizio non è al momento disponibile, riprovare tra qualche minuto", "Ok");
      })
  }

  onChangeProvincia() {
    this.getIstitutiCensimentoComune();
    this.getIstitutiCensimento();
  }

  selectIstitutoElement(item) {
    item.selected = !item.selected;
  }

  aggiornaListeIstituti(aggiungi) {
    if (aggiungi) {
      for (let istituto of this.istitutiNonSelezionati) {
        if (istituto.selected) {
          istituto.selected = false;
          this.istitutiSelezionati.push(istituto);
          this.istitutiNonSelezionati = this.istitutiNonSelezionati.filter(item => item.denominazioneIstituto != istituto.denominazioneIstituto);
          this.istitutiSelezionati = this.orderByCodiceScuola(this.istitutiSelezionati);
        }
      }
    } else {
      for (let istituto of this.istitutiSelezionati) {
        if (istituto.selected) {
          istituto.selected = false;
          this.istitutiNonSelezionati.push(istituto)
          this.istitutiSelezionati = this.istitutiSelezionati.filter(item => item.denominazioneIstituto != istituto.denominazioneIstituto)
          this.istitutiNonSelezionati = this.orderByCodiceScuola(this.istitutiNonSelezionati);
        }
      }
    }
  }

  annullaAutoDichiarazione() {
    this.router.navigate(["/errorPage"]);
  }

  goToHomeAndLogout() {
    this.modalService.closeModalGlobal();
    this.auth.deleteSession();
  }

  confermaAutoDichiarazione() {
    //gli asterischi per le input

    this.anagraficaDichiarante = this.componiAnagraficaDichiarante();
    if (this.anagraficaDichiaranteForm.status != "VALID" || !this.consensoForm.value.dichiaro || !this.consensoForm.value.chiedo) {
      if (this.controlloCampiObbligatori(this.anagraficaDichiaranteForm.controls)) {
        this.modalService.openModalGlobal("ATTENZIONE", "Compilare tutti i campi obbligatori", "ok");
      } else {
        this.controlloFormale(this.anagraficaDichiaranteForm.controls);
      }
      this.noValidForm = true;
      window.scrollTo(0, 0);
    } else if (this.controlloConfermaEmail()) {
      this.modalService.openModalGlobal("ATTENZIONE", "Le email inserite non corrispondono", "OK")
    } else if (this.istitutiSelezionati.length == 0) {
      this.modalService.openModalGlobal("ATTENZIONE", "Selezionare almeno un istituto", "ok");
    } else {
      this.modal_autodichiarazione.openModal("", "");
    }
  }

  controlloCampiObbligatori(form) {
    let campoVuoto = false;
    for (const [key, value] of Object.entries(form)) {
      let campo: any;
      campo = value;
      if (campo.errors && campo.errors.required == true) {
        campoVuoto = true;
      }
    }
    if (!this.consensoForm.value.dichiaro || !this.consensoForm.value.chiedo) {
      campoVuoto = true;
    }
    return campoVuoto;
  }

  controlloFormale(form) {
    let campoValido = true;
    let message = "I seguenti campi non sono validi: ";
    for (const [key, value] of Object.entries(form)) {
      let campo: any;
      campo = value;
      if (campo.errors != null) {
        campoValido = false;
        switch (key) {
          case 'provinciaDichiarante':
            message += "provincia di nascita (2 caratteri), "
            break;
          case 'dataNascitaDichiarante':
            message += "data di nascita (gg/mm/aaaa), "
            break;
          case 'emailDichiarante':
            message += "email, "
            break;
          case 'cellDichiarante':
            message += "telefono cellulare"
            break;
        }
      }
    }
    return campoValido ? "OK" : this.modalService.openModalGlobal("ATTENZIONE CAMPI NON VALIDI", message, "OK");
  }

  controlloConfermaEmail() {
    let campiEmailDiversi = false;
    if (this.anagraficaDichiaranteForm.value.emailDichiarante != this.anagraficaDichiaranteForm.value.confermaEmailDichiarante) {
      campiEmailDiversi = true;
    }
    return campiEmailDiversi;
  }

  preventPaste() {
    this.modalService.openModalGlobal("ATTENZIONE", "Impossibile copiare il contenuto nel campo conferma email", "OK")
    return false;
  };

  componiAnagraficaDichiarante() {
    let listaCodiciScuola = this.istitutiSelezionati.map(
      istituti => {
        return istituti.codScuola;
      });
    let anagraficaDichiarante = this.anagraficaDichiaranteForm.value;
    anagraficaDichiarante.codiceFiscaleDichiarante = this.codiceFiscaleDichiarante;
    anagraficaDichiarante.nomeDichiarante = this.utente.name;
    anagraficaDichiarante.cognomeDichiarante = this.utente.lastName;
    anagraficaDichiarante.codiciScuola = listaCodiciScuola;
    anagraficaDichiarante.tipoIstituto = this.ricercaIstitutiForm.value.tipoIstituto;
    anagraficaDichiarante.token = sessionStorage.getItem("access_token").split('.')[1];
    return anagraficaDichiarante;
  }

  censisciIstituto() {
    let anagraficaBodyDto = {
      cellDichiarante: this.anagraficaDichiarante.cellDichiarante,
      comuneDichiarante: this.anagraficaDichiarante.comuneDichiarante,
      dataNascitaDichiarante: this.anagraficaDichiarante.dataNascitaDichiarante,
      emailDichiarante: this.anagraficaDichiarante.emailDichiarante,
      provinciaDichiarante: this.anagraficaDichiarante.provinciaDichiarante,
      codiciScuola: this.anagraficaDichiarante.codiciScuola,
      tipoIstituto: this.anagraficaDichiarante.tipoIstituto,
      token: this.anagraficaDichiarante.token
    }
    this.modal_autodichiarazione.closeModal();
    let params = {
      flagAutocertificazione: this.controllaConsensi(),
      anagraficaDichiarante: anagraficaBodyDto
    }
    this.spinner.show()
    this.istitutoService.censisciIstituto(params).subscribe(result => {
      this.spinner.hide();
      if (result.message.level == "SUCCESS") {
        this.modalService.openModalGlobal("Operazione conclusa con successo", "Autodichiarazione inserita, entro 15 minuti sarà possibile accedere al portale RSA sicure", "Ok", () => this.goToHomeAndLogout(), () => this.goToHomeAndLogout());
      } else {
        this.modalService.openModalGlobal("ATTENZIONE", "Il servizio non è al momento disponibile, riprovare tra qualche minuto", "Ok");
      }
    },
      err => {
        this.spinner.hide();
        if (err.error && err.error.exception.includes("AutodichiarazioneDuplicataException")) {
          this.modalService.openModalGlobal("ATTENZIONE", err.error.message, "Ok");
        } else {
          this.modalService.openModalGlobal("ATTENZIONE", "Il servizio non è al momento disponibile, riprovare tra qualche minuto", "Ok");
        }
      })
  }

  controllaConsensi(): number {
    let flagAutocertificazione = 0;

    if (this.consensoForm.value.chiedo) {
      flagAutocertificazione++;
    }

    if (this.consensoForm.value.dichiaro) {
      flagAutocertificazione++;
    }

    return flagAutocertificazione;
  }

}

