import { Injectable } from '@angular/core';
import { Docente } from '../../model/docente';
import { Utente } from '../../model/utente'
import { environment } from './../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  docente: Docente;
  flagCambiaIstituto: Boolean;
  flagDisabilitaAccedi: Boolean;

  filtriRicercaDocenti;

  constructor() { }

  deleteSession(): void {
    sessionStorage.clear();
    window.location.href = environment.logoutUrl + environment.redirect_uri;
  }

  getFiltriDocenteList() {
    return this.filtriRicercaDocenti;
  }

  setFiltriDocenteList(ricercaFiltri) {
    this.filtriRicercaDocenti = ricercaFiltri;
  }

  setUtente(utente): void {
    sessionStorage.setItem('utente', JSON.stringify(utente));
  }

  setFlagCambiaIstituto(value: Boolean): void {
    this.flagCambiaIstituto = value;
  }

  getFlagDisabilitaAccedi(): Boolean {
    return this.flagDisabilitaAccedi;
  }

  setFlagDisabilitaAccedi(value: Boolean): void {
    this.flagDisabilitaAccedi = value;
  }

  getFlagCambiaIstituto(): Boolean {
    return this.flagCambiaIstituto
  }

  setIstituto(istituto): void {
    sessionStorage.setItem('istituto', JSON.stringify(istituto));
  }

  getCodiceScuola(): void {
    return JSON.parse(sessionStorage.getItem('istituto')).codice;
  }

  getUtente(): any {
    return JSON.parse(sessionStorage.getItem('utente'));
  }

  getIstituto(): any {
    return JSON.parse(sessionStorage.getItem('istituto'));
  }

  setDocente(docente): void {
    this.docente = docente;
  }

  getDocente(): Docente {
    return this.docente;
  }
}
