<app-header></app-header>
<div class="page-content">
    <div>
        <button type="button" (click)="navigateTo('/listaPersonale')" class="btn button-active-docenti">Gestione
            Persone
            RSA
        </button>
        <button type="button" (click)="navigateTo('/listaPrescrizioni')" class="btn button-prescrizione">Gestione Codice
            RSA</button>
    </div>
    <div class="sezione-titolo">
        <h3>Ricerca Persone RSA </h3>
    </div>

    <!-- form start -->
    <form name='form' role="form" class="form" [formGroup]="ricercaForm" (ngSubmit)="cerca()">
        <div class="row sezione-textEditor">
            <div class="form-group col-12 col-md-4">
                <label class="control-label">Cognome: </label>
                <input class="form-control form-textEditor form-control-sm" type="text" id='cognome'
                    formControlName="cognome">
            </div>
            <div class="form-group col-12 col-md-4">
                <label class="control-label">Nome: </label>
                <input class="form-control form-textEditor form-control-sm" type="text" id='' formControlName='nome'>
            </div>
            <div class="form-group col-12 col-md-4">
                <label class="control-label">Codice Fiscale: </label>
                <input class="form-control form-textEditor form-control-sm" type="text" id='codiceFiscale'
                    formControlName='cf'>
                <validation-messages [control]="ricercaForm.controls.cf"></validation-messages>
            </div>
        </div>
        <div class="row justify-content-end sezione-border">
            <div class="col-12 col-md-12">
                <div class="align-right">
                    <button class="btn button-docenti" type="button" (click)="pulisciCampi()">Pulisci campi</button>
                    <button class="btn button-docenti" type="submit">Cerca</button>
                </div>
            </div>
        </div>
    </form>
    <!-- form -->

    <div class="sezione-titolo">
        <h3 *ngIf="showPersonaleScolasticoList && docentiList.length > 0">Risultato della ricerca</h3>
        <h3 *ngIf="showPersonaleScolasticoList && docentiList.length == 0">Nessun record presente</h3>
    </div>

    <div *ngIf="showPersonaleScolasticoList">
        <!-- PAGINAZIONE -->
        <div class="row sezione-paginazione">
            <div class="col-12 col-md-4 justify-content-end">
            </div>
            <div class="col-12 col-md-4">
                <nav>
                    <ul class="pagination justify-content-center">
                        <li class="pagination-text">Visualizzati
                            {{calcolaPrimoValoreElementiVisualizzati()}}-{{calcolaSecondoValoreElementiVisualizzati()}}
                            di
                            {{totalElements}}: </li>
                        <li class="page-item"><a class="page-link" (click)="decreasePage()"><i
                                    class="fas fa-caret-left"></i></a>
                        </li>
                        <li class="page-item"><a class="page-link">{{this.page}}</a></li>
                        <li class="page-item"><a class="page-link" (click)="increasePage()"><i
                                    class="fas fa-caret-right"></i></a></li>
                    </ul>
                </nav>
            </div>
            <div class="col-12 col-md-4 align-right">
                <label class="control-label">Visualizza Elementi: </label>
                <select class="form-control form-control-sm select-elementi" id="opzione" [(ngModel)]="elements"
                    (change)="changeTotalElements()">
                    <option *ngFor="let option of visualizzaElementiList" value="{{option}}">{{option}}</option>
                </select>
            </div>
        </div>

        <table class="table table-responsive-xl" id="listaDocentiTable">
            <thead>
                <tr>
                    <th *ngFor="let header of headerList" scope="col" class="align-{{header.align}} table-docenti">
                        {{header.value}}
                        <i style="cursor:pointer" *ngIf="header.orderDirection == 'ASC'"
                            (click)="setOrdinamento(header)" class=" fa fa-arrow-up"></i>
                        <i style="cursor:pointer" *ngIf="header.orderDirection == 'DESC'"
                            (click)="setOrdinamento(header)" class=" fa
                        fa-arrow-down"></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let docente of docentiList, let i = index">
                    <td *ngFor="let property of propertyList" scope="col" class="align-{{property.align}}">
                        {{docente[property.value]}}
                    </td>
                    <td class="align-center table-docenti">
                        <button class="button-awesome-docenti" (click)="modifica(docente)">
                            <i class="fa fa-pencil-alt"></i>
                        </button>
                    </td>
                    <td class="align-center table-docenti">
                        <button class="button-awesome-docenti " (click)="elimina(docente)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                    <td class="align-center table-docenti">
                        <button class="button-awesome-docenti " (click)="richiesta(docente)">
                            <i class="fa fa-share-square"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="row justify-content-end">
            <div class="col-12 col-md-12">
                <div class="align-right">
                    <!-- <button type="button" class="btn button-docenti" (click)="caricamentoDaCsv()">Carica dati da
                        csv</button> -->
                    <button type="button" class="btn button-docenti" (click)="aggiungiDocente()">Aggiungi Nuovo</button>
                </div>
            </div>
        </div>


    </div>

</div>
<app-footer></app-footer>
<div *ngIf="modale_elimina.open" class="modale-background">
    <div class="modale-content">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{modale_elimina.title}}</h5>
                    <button type="button" class="close" (click)="modale_elimina.closeModal()" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    {{modale_elimina.message}}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn button-docenti" (click)="modale_elimina.closeModal()"
                        data-dismiss="modal">ANNULLA</button>
                    <button type="button" class="btn button-docenti"
                        (click)="eliminaPersonale(modale_elimina.personale)" data-dismiss="modal">ELIMINA</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="modale_caricamento_csv.open" class="modale-background">
    <div class="modale-content_upload_csv" id="modale-content_upload_csv">
        <div class="modal-dialog">
            <div class="modal-content modale-content_upload_csv-interno">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{modale_caricamento_csv.title}}</h5>
                    <button type="button" class="close" (click)="closeModaleUpload()" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row justify-content-end">
                        <button class="btn button-docenti" (click)="opendModalIstruzioni()"><i
                                class="fas fa-question-circle"></i>
                            Istruzioni</button>
                        <button class="btn button-docenti" (click)="scaricaTemplate()">
                            <i class="fas fa-file-csv"></i>
                            Esempio CSV</button>
                    </div>
                    <div class="row sezione-upload-file">
                        <h4 class="col-12 col-md-3">
                            <div class="breadCrumb row">
                                <div id="button"
                                    [className]="csvUploaded ? 'active_button btn rounded' : 'btn rounded'">
                                    <span class="number_button">1</span>
                                </div>
                                <span class="text_button">Scegli file</span>
                            </div>
                        </h4>
                        <div id="upload_csv">
                            <input #inputCsv type="file" id="file" (change)="gestisciFile($event.target.files[0])"
                                (click)="rimuoviFileSelect($event)" accept=".csv ">
                            <button class="btn button-docenti ">
                                <i class="fas fa-folder-open"></i> Scegli File
                            </button>
                        </div>
                        <div class="csvUploaded_name" *ngIf="csvUploaded">
                            <h4>
                                {{csvUploaded.name}}
                            </h4>
                        </div>
                        <div class="csvUploaded_name" *ngIf="csvUploaded">
                            <h4 (click)="removeFile()">
                                <i class="fas fa-times"></i>
                            </h4>
                        </div>
                    </div>
                    <div class="row sezione-upload-file sezione-border">
                        <h4 class="col-12 col-md-3">
                            <div class="breadCrumb row">
                                <div id="button" class=" active_button btn rounded"
                                    [className]="uploadFileSuccess ? 'active_button btn rounded' : 'btn rounded'">
                                    <span class="number_button">2</span>
                                </div>
                                <span class="text_button">Carica file</span>
                            </div>
                        </h4>
                        <div>
                            <button class="btn button-docenti " (click)="uploadCsv()">
                                <i class="fas fa-upload"></i> Carica file
                            </button>
                        </div>
                    </div>
                    <div class="sezione-tabella-csv-upload">
                        <h4>Elenco file caricati</h4>
                        <table *ngIf="csvList.length >0" class="table table-responsive-sm" id="listaDocentiTable">
                            <thead>
                                <tr>
                                    <th *ngFor="let header of headerListTabellaUploadCsv" scope="col"
                                        class="align-{{header.align}} table-docenti">
                                        {{header.value}}
                                        <i style="cursor:pointer" *ngIf="header.orderDirection == 'ASC'"
                                            (click)="setOrdinamento(header)" class=" fa fa-arrow-up"></i>
                                        <i style="cursor:pointer" *ngIf="header.orderDirection == 'DESC'"
                                            (click)="setOrdinamento(header)" class=" fa
                                        fa-arrow-down"></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let csv of csvList, let i = index">
                                    <td *ngFor="let property of propertyListTabellaUploadCsv" scope="col"
                                        class="align-{{property.align}}">
                                        {{csv[property.value]}}
                                    </td>
                                    <td class="align-center table-docenti">
                                        <button *ngIf="csv.statoElaborazioneDescr == 'ELABORATO' && csv.isScarti"
                                            class="button-awesome-docenti" (click)="scartiCsv(csv)">
                                            <i class="fa fa-download"></i>
                                        </button>
                                        <p *ngIf="csv.statoElaborazioneDescr == 'ELABORATO' && !csv.isScarti">Nessuno
                                            scarto</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn button-docenti" (click)="recuperaListaFileCsv()"
                        data-dismiss="modal">Aggiorna stato</button>
                    <button type="button" class="btn button-docenti" (click)="closeModaleUpload()"
                        data-dismiss="modal">Chiudi</button>
                </div>
            </div>
        </div>
    </div>
</div>




<div *ngIf="modal_istruzioni.open" class="modale-background">
    <div class="modale-content">
        <div class="modal-dialog">
            <div class="modal-content modal-content-istruzioni">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Guida al caricamento del file csv</h5>
                    <button type="button" class="close" (click)="modal_istruzioni.closeModal()" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body modal-content-istruzioni-content" id="istruzioni-content">
                    <p>Questa funzionalità consente di censire le persone in RSA contenuto in un file csv
                        (comma separated values).</p>
                    <p> E' necessario caricare il file e attenderne la successiva elaborazione da parte del
                        sistema.</p>
                    <p>Il file csv deve seguire le seguenti regole:</p>
                    <p style="padding-left: 15px;"> 1. Non deve riportare una prima riga di intestazione</p>
                    <p style="padding-left: 15px;"> 2. Deve avere il seguente formato: Cognome;Nome;Codice Fiscale;Data
                        di Nascita;
                        Cellulare;Email;Ruolo</p>
                    <p>Di seguito i passi da seguire per portare a termine l'operazione:</p>
                    <p><b> 1. SELEZIONE DEL FILE:</b></p>
                    <p>Premere il pulsante "Scegli File": Si apre una finestra di esplora risorse che consente
                        di selezionare il file csv che si intende caricare. Dopo la selezione la finestra si
                        chiude ed il nome del file selezionato viene visualizzato di fianco al bottone.</p>
                    <p><b> 2. CARICAMENTO DEL FILE:</b></p>
                    <p>Premere il pulsante "Carica File": il file indicato al punto 1. viene preso in carico dal
                        sistema per la successiva elaborazione.</p>
                    <p>Il file viene aggiunto in stato DA ELABORARE alla lista sottostante che contiene tutti i
                        file presi in carico dal sistema.</p>
                    <p><b> 3. AGGIORNAMENTO DELLO STATO</b></p>
                    <p>Attraverso la lista dei file presi in carico dal sistema è possibile verificare lo stato
                        di elaborazione attraverso l'apposita colonna "Stato".
                        Per aggiornare lo stato è possibile premere il pulsante "Aggiorna Stato".</p>
                    <p>Gli stati possibili sono:</p>
                    <p style="padding-left: 15px;"><b>DA ELABORARE:</b> file acquisito non ancora elaborato</p>
                    <p style="padding-left: 15px;"><b>IN ELABORAZIONE:</b> in fase di elaborazione</p>
                    <p style="padding-left: 15px;"><b>ELABORATO:</b> elaborazione conclusa</p>
                    <p></p>
                    <p><b>4. SCARICA ERRORI CSV</b></p>
                    <p>Attraverso la lista dei file presi in carico dal sistema è possibile scaricare
                        l'eventuale lista degli errori di elaborazione.</p>
                    <p><b><u>ATTENZIONE:</u></b> Vista la possibile concomitanza di caricamento di più RSA
                        contemporaneamente, l'elaborazione del file potrebbe durare alcuni minuti.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn button-docenti" (click)="modal_istruzioni.closeModal()"
                        data-dismiss="modal">Chiudi</button>
                </div>
            </div>
        </div>
    </div>
</div>