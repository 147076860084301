import { Component, NgModuleFactoryLoader, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Costanti } from './../../utility/costanti'
import { Prescrizione } from './../../model/prescrizione';
import { PropertyTable } from './../../model/propertyTable';
import { AuthService } from '../../service/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValidationService } from './../../service/form/validation.service'
import { ModalService } from './../../service/modale/modal.service'
import { PrintPdfService } from './../../service/print-pdf.service'
import { Modal } from './../../model/modal';
import * as moment from 'moment';
import { Utente } from 'src/app/model/utente';
import { PrescrizioniService } from './../../service/prescrizioni.service'

@Component({
  selector: 'app-lista-prescrizioni',
  templateUrl: './lista-prescrizioni.component.html',
  styleUrls: ['./lista-prescrizioni.component.css']
})
export class ListaPrescrizioniComponent implements OnInit {
  modalStampaNRE: Modal;
  modalStorico: Modal;
  dataStampa: string;
  ricercaForm: any;
  headerList: PropertyTable[]
  propertyList: PropertyTable[];
  headerListStampaTable: PropertyTable[];
  propertyListStampaTable: PropertyTable[];
  headerListStoricoTable: PropertyTable[];
  propertyListStoricoTable: PropertyTable[];
  visualizzaElementiList: string[];
  prescrizioneList: Prescrizione[];
  prescrizioneListSelectedRichiesta: Prescrizione[];
  prescrizioneListSelectedStampa: Prescrizione[];
  personaleSelezionato: Prescrizione;
  storicoList: any;
  utente: Utente;
  showPrescrizioniList: Boolean;
  istituto;
  page: number;
  elements: number;
  modal_error: Modal;
  orderBy: String;
  orderDirection: String;
  totalElements: number;
  totalPage: number

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private prescrizioniService: PrescrizioniService,
    private router: Router,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    moment.locale("it");
    this.utente = this.auth.getUtente();
    this.istituto = this.auth.getIstituto();
    this.modalStampaNRE = new Modal();
    this.modalStorico = new Modal();
    this.modal_error = new Modal();
    this.buildRicercaForm();
    this.headerList = Costanti.HEADER_PRESCRIZIONI;
    this.propertyList = Costanti.PROPERTY_PRESCRIZIONI;
    this.headerListStampaTable = Costanti.HEADER_STAMPA_TABLE;
    this.propertyListStampaTable = Costanti.PROPERTY_STAMPA_TABLE;
    this.headerListStoricoTable = Costanti.HEADER_STORICO_TABLE;
    this.propertyListStoricoTable = Costanti.PROPERTY_STORICO_TABLE
    this.visualizzaElementiList = Costanti.ELEMENT_SELECT_OPTION;
    this.elements = Costanti.ELEMENT_DEFAULT_OPTION;
    this.orderBy = Costanti.ORDERBY_PRESCRIZIONI_DEFAULT;
    this.orderDirection = Costanti.ORDERDIRECTION_PRESCRIZIONI_DEFAULT;
    this.page = 1;
    this.getPrescrizioni();

  }

  buildRicercaForm() {
    this.prescrizioneListSelectedRichiesta = [];
    this.prescrizioneListSelectedStampa = [];

    let docente = this.auth.getDocente();
    if (docente != null) {
      this.ricercaForm = this.formBuilder.group({
        cognome: [''],
        nome: [''],
        cfOperatore: [docente.cfOperatore],
        prescrizione_no: false,
        prescrizione_si: false,
        prenotazione_si: false,
        erogato_si: false,
      });
    } else {
      this.ricercaForm = this.formBuilder.group({
        cognome: [''],
        nome: [''],
        cfOperatore: [''],
        prescrizione_no: false,
        prescrizione_si: false,
        prenotazione_si: false,
        erogato_si: false,
      });
    }
  }

  navigateTo(url) {
    this.router.navigate([url])
  }

  cerca() {
    this.getPrescrizioni();
  }

  getPrescrizioni(): void {
    this.showPrescrizioniList = false;
    this.spinner.show();
    let parametriRicercaForm = this.ricercaForm.value;
    let params = {
      codiceScuola: this.auth.getCodiceScuola(),
      cognome: parametriRicercaForm.cognome,
      nome: parametriRicercaForm.nome,
      cf: parametriRicercaForm.cfOperatore,
      prescrizione_no: parametriRicercaForm.prescrizione_no,
      prescrizione_si: parametriRicercaForm.prescrizione_si,
      prenotazione_si: parametriRicercaForm.prenotazione_si,
      erogato_si: parametriRicercaForm.erogato_si,
      page: this.page,
      elements: this.elements,
      orderBy: this.orderBy,
      orderDirection: this.orderDirection
    }
    this.prescrizioniService.getPrescrizioni(params).subscribe(
      result => {
        this.spinner.hide();
        this.totalElements = result.response.totalElements;
        this.totalPage = result.response.totalPage;
        let prescrizioni = result.response.content;
        if (this.auth.getDocente()) {
          prescrizioni = prescrizioni.filter(item => item.cfOperatore == this.auth.getDocente().cfOperatore)
        }
        this.prescrizioneList = prescrizioni.map(item => {
          return new Prescrizione(item);
        });
        this.showPrescrizioniList = true;
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  selectAllRichiesta() {
    var elementValue = (<HTMLInputElement>document.getElementById("checkAll-richiesta")).checked;
    for (let i = 0; i < this.prescrizioneList.length; i++) {
      const check = (<HTMLInputElement>document.getElementById("check-richiesta-" + i));
      if (check) {
        check.checked = elementValue;
        this.prescrizioneListSelectedRichiesta = this.handleSelectCheckBox(this.prescrizioneList[i], elementValue, this.prescrizioneListSelectedRichiesta) //solo se esiste check, e quindi non ha nre lo aggiungo
      }
    }
  }

  selectAllStampa() {
    var elementValue = (<HTMLInputElement>document.getElementById("checkAll-stampa")).checked;
    for (let i = 0; i < this.prescrizioneList.length; i++) {
      const check = (<HTMLInputElement>document.getElementById("check-stampa-" + i));
      if (check) {
        check.checked = elementValue;
        this.prescrizioneListSelectedStampa = this.handleSelectCheckBox(this.prescrizioneList[i], elementValue, this.prescrizioneListSelectedStampa) //solo se esiste check, e quindi non ha nre lo aggiungo
      }
    }
  }


  selectPrescrizioneRichiesta(prescrizione, id) {
    var elementValue = (<HTMLInputElement>document.getElementById("check-richiesta-" + id)).checked;
    this.prescrizioneListSelectedRichiesta = this.handleSelectCheckBox(prescrizione, elementValue, this.prescrizioneListSelectedRichiesta)
  }

  selectPrescrizioneStampa(prescrizione, id) {
    var elementValue = (<HTMLInputElement>document.getElementById("check-stampa-" + id)).checked;
    this.prescrizioneListSelectedStampa = this.handleSelectCheckBox(prescrizione, elementValue, this.prescrizioneListSelectedStampa)
  }

  handleSelectCheckBox(prescrizione, boolean, list): Prescrizione[] {
    if (boolean) {
      if (!list.find(item => item.cfOperatore == prescrizione.cfOperatore)) {
        list.push(prescrizione)
      }
    } else {
      list = list.filter(item => item.cfOperatore != prescrizione.cfOperatore);
    }
    return list;
  }

  richiediTampone(): void {
    if (this.prescrizioneListSelectedRichiesta.length > 0) {
      let cfList = this.prescrizioneListSelectedRichiesta.map(item => { return item.cfOperatore });
      let params = {
        codiceScuola: this.auth.getCodiceScuola(),
        cf: cfList
      }
      this.spinner.show()
      this.prescrizioniService.richiediNre(params).subscribe(
        result => {
          this.spinner.hide()
          if (result.message.level == "ERROR") {
            this.modalService.openModalGlobal("Attenzione", "Si è verificato un errore nella generazione del codice RSA", "ok")
          } else {
            this.getPrescrizioni();
            this.messaggioRichiediNre(result.response)
            this.prescrizioneListSelectedRichiesta = [];
          }
        },
        error => {
          this.spinner.hide()
          this.modal_error.openModal("ATTENZIONE", "Si è verificato un errore")
        }
      )
    } else {
      this.modal_error.openModal("ATTENZIONE", "Selezionare almeno un record")
    }

  }

  messaggioRichiediNre(list): void {
    let mex = "";
    if (list.length === 0) {
      mex = "Codice RSA richiesto per tutti gli utenti selezionati"
      this.modalService.openModalGlobal("Operazione effettuata con successo", mex, "ok")
    } else {
      mex = "Non è stato possibile richiedere il codice RSA per l'utente:  "
      for (let cf of list) {
        mex += cf + " "
      }
      this.modalService.openModalGlobal("Attenzione", mex, "ok")
    }
  }

  stampaNre(): void {
    var elementID = "#listaStampa";
    PrintPdfService.stampaTabella(this.auth.getIstituto(), this.dataStampa, elementID);
    this.modalStampaNRE.closeModal();
  }

  storicoRichieste(prescrizione): void {
    this.spinner.show();
    this.storicoList = [];
    this.personaleSelezionato = prescrizione;
    
    this.modalStorico.openModal("Storico Richieste", "");
    let params = {
      codiceScuola: this.auth.getCodiceScuola(),
      cf: prescrizione.cfOperatore,
    }
    
    this.prescrizioniService.getStorico(params).subscribe(
      result => {
        this.spinner.hide();
        for (let item of result.response) {
          this.storicoList.push({ 
            nreT: item.nreT, 
            dataPrenotazione: (item.dataPrenotazione == null || item.dataPrenotazione == undefined ? '-' : moment(item.dataPrenotazione).format("DD/MM/yyyy HH:mm:ss")),
            dataCreazione: (item.dataCreazione == null || item.dataCreazione == undefined ? '-' : moment(item.dataCreazione).format("DD/MM/yyyy HH:mm:ss"))
          })
        }      
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  verificaStampa(prescrizione): boolean {
    return prescrizione.nreT == "-" ? false : true;
  }

  stampaPersonaleSelezionato() {
    if (this.prescrizioneListSelectedStampa.length > 0) {
      this.dataStampa = moment(new Date()).format("LLL");
      this.modalStampaNRE.openModal("Stampa codice RSA per le persone selezionate", "");
    } else {
      this.modalService.openModalGlobal("Attenzione", "Selezionare almeno un record", "Ok");
    }
  }

  setOrdinamento(header) {
    if (header.orderDirection == "DESC") {
      header.orderDirection = "ASC";
    } else {
      header.orderDirection = "DESC";
    }
    this.orderBy = header.orderBy;
    this.orderDirection = header.orderDirection;

    this.getPrescrizioni();

  }

  changeTotalElements() {
    this.page = 1;
    this.getPrescrizioni()
  }

  increasePage() {
    if (this.page != this.totalPage && this.totalElements > 0) {
      this.page++;
      this.getPrescrizioni();
    }
  }

  setPage(numero) {
    this.page = numero;
    this.getPrescrizioni();
  }

  decreasePage() {
    if (this.page != 1) {
      this.page -= 1;
      this.getPrescrizioni();
    }
  }

  pulisciCampi() {
    this.auth.setDocente(null);
    this.ricercaForm.reset();
    this.getPrescrizioni();
  }

  calcolaPrimoValoreElementiVisualizzati(): number {
    return this.totalElements > 0 ? this.elements * (this.page - 1) + 1 : 0;
  }

  calcolaSecondoValoreElementiVisualizzati(): number {
    return (this.elements * (this.page)) > this.totalElements ? this.totalElements : (this.elements * (this.page))
  }



}
