<div class="wrapper">
    <app-header></app-header>
    <!--     <app-breadcrumb></app-breadcrumb>
 -->
    <div class="justify-content-center page-content">
        <div class="home-content">
            <div *ngIf="avvisoDisservizio" class="alert alert-danger alert-box text_2">
                <p>ATTENZIONE. Il prossimo 30/10 dalle ore 18:00 alle ore 20:00 il servizio non sarà disponibile a causa
                    di un
                    intervento manutentivo.</p>
            </div>
            <h3 class="text_1">
                Emergenza COVID-19. DGRT 546/2021 allegato B.
            </h3>
            <h3 class="text_1"> Sistema di monitoraggio per le infezioni da SARS- CoV-2 "RSA
                Sicure"</h3>
            <div class="alert alert-info alert-box text_2">
                <p>Sistema regionale per la generazione dei codici RSA utili alla prenotazione dei tamponi da parte
                    delle persone in RSA in ottemperanza alla DGRT 546/2021 allegato B.</p>
                <p>L’accesso al sistema è permesso alle <b>persone in RSA</b> munite di <b>SPID</b>, <b>CNS</b>
                    o
                    <b>CIE</b>.
                </p>
                <p>Il censimento delle persone in RSA, la generazione del codice RSA e la relativa consegna via
                    email ai diretti interessati rappresentano
                    l’operatività funzionale del sistema.</p>
                <p>Attraverso il <b>codice RSA</b> è possibile eseguire la prenotazione del test antigenico
                    semi-rapido, che
                    rappresenta la fase iniziale del processo che prosegue
                    con le successive fasi di esecuzione e refertazione del tampone molecolare.
                </p>
            </div>
            <div class="access-button">
                <button type="button" class="btn button-docenti" (click)="accedi()">Accedi</button>
            </div>
        </div>
    </div>
    <app-footer></app-footer>

</div>