import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IstitutoService } from './../../service/istituto.service';
import { ModalService } from './../../service/modale/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-utente-non-abilitato',
  templateUrl: './utente-non-abilitato.component.html',
  styleUrls: ['./utente-non-abilitato.component.css']
})
export class UtenteNonAbilitatoComponent implements OnInit {

  autodichiarazionePresente: boolean;

  constructor(
    private router: Router,
    private istitutoService: IstitutoService,
    private modalService: ModalService,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.autodichiarazionePresente = false;
    //this.getautoDichiarazione();

  }

  getautoDichiarazione() {
    this.spinner.show();
    this.istitutoService.getAutodichiarazione().subscribe(result => {
      this.spinner.hide();
      if (result.response != null) {
        this.modalService.openModalGlobal("ATTENZIONE", "Autodichiarazione già depositata", "Ok");
        //this.autodichiarazionePresente = true;
      } else {
        this.router.navigate(["/dichiarazione-sostitutiva"])
      }
    },
      err => {
        this.spinner.hide();
      }).add(this.spinner.hide());
  }

  goToDichiarazione() {
    this.router.navigate(["/dichiarazione-sostitutiva"])
  }

}
