import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgxSpinnerModule } from "ngx-spinner";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './component/footer/footer.component';
import { HeaderComponent } from './component/header/header.component';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { HomeComponent } from './page/home/home.component';
import { ListaPrescrizioniComponent } from './page/lista-prescrizioni/lista-prescrizioni.component';
import { ListaDocentiComponent } from './page/lista-docenti/lista-docenti.component';
import { UserComponent } from './component/user/user.component';
import { DettaglioDocenteComponent } from './page/dettaglio-docente/dettaglio-docente.component';
import { ValidationService } from './service/form/validation.service';
import { ValidationMessagesComponent } from './component/validation-messages/validation-messages.component';
import { SelezionaIstitutoComponent } from './page/seleziona-istituto/seleziona-istituto.component'
import { PersonaleScolasticoService } from './service/personale-scolastico.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PrescrizioniService } from './service/prescrizioni.service';
import { UtenteNonAbilitatoComponent } from './page/utente-non-abilitato/utente-non-abilitato.component';
import { InterceptorService } from './service/token/interceptor.service';
import { SessioneScadutaComponent } from './page/sessione-scaduta/sessione-scaduta.component'
import { PopoverModule } from 'ngx-smart-popover';
import { DichiarazioneSostitutivaComponent } from './page/dichiarazione-sostitutiva/dichiarazione-sostitutiva.component';
import { DisservizioComponent } from './page/disservizio/disservizio.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    BreadcrumbComponent,
    HomeComponent,
    ListaPrescrizioniComponent,
    ListaDocentiComponent,
    UserComponent,
    DettaglioDocenteComponent,
    ValidationMessagesComponent,
    SelezionaIstitutoComponent,
    UtenteNonAbilitatoComponent,
    SessioneScadutaComponent,
    DichiarazioneSostitutivaComponent,
    DisservizioComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule
  ],
  providers: [
    PersonaleScolasticoService,
    PrescrizioniService,
    ValidationService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
