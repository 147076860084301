<div class="container-header sezione-border">
    <div class="header">
        <div class="logoRegioneToscana" (click)="goHome()">
            <img alt="Regione-Toscana" src="assets/img/logoToscana.png" style="height: 61px;">
            <img alt="SST" style="height: 61px; margin-left: 3px;" src="assets/img/Logo_SST.png">
            <h1 class="rsaSicureTitle">RSA Sicure</h1>
        </div>
    </div>
    <div class="row sezione-user">
        <app-user></app-user>
    </div>
</div>