export const environment = {
  production: false,
  //sis_rsa_sicure_be: "sis-rsa-sicure",
  //sis_rsa_sicure_censimento: "sis-rsa-sicure/censimento",
  sis_rsa_sicure_be: "https://apistage.regione.toscana.it/C06/covid19-rsa-sicure/v1/sis-rsa-sicure",
  sis_rsa_sicure_censimento: "https://apistage.regione.toscana.it/C06/covid19-rsa-sicure-censimento/v1",
  redirect_uri: 'https://rsasicure-test.sanita.toscana.it',
  client_id: 'covid19-rsa-sicure-c1',
  issuer: 'https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa',
  authUrl: 'https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/auth',
  tokenUrl: 'https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/token',
  logoutUrl: 'https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/logout?redirect_uri=',
  tokenSessionTime: 20,
  refresh_tokenTime: 10,
  access_tokenTime: 5
};