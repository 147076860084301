import * as moment from 'moment';

export class Docente {
    cognome: string;
    nome: string;
    cfOperatore: string;
    dataNascita: string;
    telefono: string;
    email: string;
    descOperatore: string;
    codeOperatore: string

    constructor(docente) {
        moment.locale("it");

        this.cognome = docente.cognome;
        this.nome = docente.nome;
        this.cfOperatore = docente.cfOperatore.toUpperCase();
        this.dataNascita = moment(docente.dataNascita).format("L");
        this.telefono = docente.telefono;
        this.email = docente.email;
        this.descOperatore = docente.descOperatore;
        this.codeOperatore = docente.codeOperatore;
    }

}