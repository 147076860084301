import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disservizio',
  templateUrl: './disservizio.component.html',
  styleUrls: ['./disservizio.component.css']
})
export class DisservizioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
