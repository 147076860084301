import { Injectable } from '@angular/core';
import { Regex } from './../../utility/regex';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  static validatePhone(control) {
    if (control.value) {
      let phoneValid = control.value.match(Regex.CELL_REGEXP);
      if (phoneValid || !control.value) {
        return null;
      } else {
        return { invalidPhone: true };
      }
    }
  }

  static validateData(control) {
    var dataPattern = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
    if (control.value) {
      let dataValid = dataPattern.test(control.value)
      if (dataValid || !control.value) {
        return null;
      } else {
        return { invalidData: true };
      }
    }
  }

  static mustbeTrue(control) {
    if (control.value) {
      return { invalidCheck: true };
    } else {
      return null;
    }
  }

  static validateEmail(control) {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (control.value) {
      let emailValida = emailPattern.test(control.value);
      if (emailValida || !control.value) {
        return null;
      } else {
        return { invalidEmail: true };
      }
    }
  }

  static validateCFLength(control) {
    if (control.value) {
      let cfValido = control.value.length == 16;
      if (cfValido || !control.value) {
        return null;
      } else {
        return { invalidCF: true };
      }
    }
  }

  static province(control) {
    if (control.value) {
      let provinciaValida = control.value.length == 2;
      if (provinciaValida || !control.value) {
        return null;
      } else {
        return { invalidProvince: true };
      }
    }
  }

  static validateCF(control) {
    let cfValido = control.value.match(Regex.CF_REGEX);
    if (cfValido || !control.value) {
      return null;
    } else {
      return { invalidCF: true };
    }
  }
}
