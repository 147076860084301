import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError } from 'rxjs/internal/operators/catchError';
import { Observable } from 'rxjs/internal/Observable';
import { pipe, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IstitutoService {

  constructor(private http: HttpClient) { }

  setHeaders() {
    return {
      httpOptions: {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
        })
      }
    }
  }

  getIstitutiCensimento(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.codTipoIstituto ? "codTipoIstituto=" + params.codTipoIstituto : "";
    queryParams += params.provincia ? "&provincia=" + params.provincia : "";
    queryParams += params.denominazioneIstituto ? "&denominazioneIstituto=" + params.denominazioneIstituto : "";
    queryParams += params.organismoScolastico ? "&organismoScolastico=" + params.organismoScolastico : "";
    queryParams += params.comune ? "&comune=" + params.comune : "";
    return this.http.get<any>(environment.sis_rsa_sicure_censimento + "/istituti" + queryParams, options.httpOptions);
  }

  getAutodichiarazione() {
    const options = this.setHeaders();
    return this.http.get<any>(environment.sis_rsa_sicure_censimento + "/autodichiarazione", options.httpOptions);
  }

  getIstitutiCensimentoDirigente() {
    const options = this.setHeaders();
    return this.http.get<any>(environment.sis_rsa_sicure_be + "/richieste/istituti", options.httpOptions);
  }

  getIstitutiCensimentoProvincia() {
    const options = this.setHeaders();
    return this.http.get<any>(environment.sis_rsa_sicure_censimento + "/istitutiProvincia", options.httpOptions);
  }

  getIstitutiCensimentoComune(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.provincia ? "provincia=" + params.provincia : "";
    return this.http.get<any>(environment.sis_rsa_sicure_censimento + "/istitutiComune" + queryParams, options.httpOptions);
  }

  getIstitutiCensimentoTipoOrganismoScolastico() {
    const options = this.setHeaders();
    return this.http.get<any>(environment.sis_rsa_sicure_censimento + "/istitutiTipoOrganismoScolastico", options.httpOptions);
  }

  getTipoIstituto() {
    const options = this.setHeaders();
    return this.http.get<any>(environment.sis_rsa_sicure_censimento + "/tipoIstituto", options.httpOptions);

  }

  censisciIstituto(params) {
    const options = this.setHeaders();
    let queryParams = "?";
    queryParams += params.flagAutocertificazione != null ? "flagAutocertificazione=" + params.flagAutocertificazione : "";
    return this.http.post<any>(environment.sis_rsa_sicure_censimento + "/censisciIstituto" + queryParams, params.anagraficaDichiarante, options.httpOptions);
  }
}
