import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './page/home/home.component';
import { ListaPrescrizioniComponent } from './page/lista-prescrizioni/lista-prescrizioni.component';
import { ListaDocentiComponent } from './page/lista-docenti/lista-docenti.component';
import { DettaglioDocenteComponent } from './page/dettaglio-docente/dettaglio-docente.component';
import { SelezionaIstitutoComponent } from './page/seleziona-istituto/seleziona-istituto.component';
import { GuardService } from './service/auth/guard.service'
import { UtenteNonAbilitatoComponent } from './page/utente-non-abilitato/utente-non-abilitato.component';
import { SessioneScadutaComponent } from './page/sessione-scaduta/sessione-scaduta.component';
import { DichiarazioneSostitutivaComponent } from './page/dichiarazione-sostitutiva/dichiarazione-sostitutiva.component';
import { DisservizioComponent } from './page/disservizio/disservizio.component';


@NgModule({
  imports: [RouterModule.forRoot([
    { path: 'home', component: HomeComponent },
    { path: 'listaPrescrizioni', component: ListaPrescrizioniComponent, canActivate: [GuardService] },
    { path: 'listaPersonale', component: ListaDocentiComponent, canActivate: [GuardService] },
    { path: 'dettaglio-persone', component: DettaglioDocenteComponent, canActivate: [GuardService] },
    { path: 'seleziona-rsa', component: SelezionaIstitutoComponent },
    { path: 'errorPage', component: UtenteNonAbilitatoComponent },
    { path: 'sessionExpired', component: SessioneScadutaComponent },
    { path: 'disservizio', component: DisservizioComponent },
    //{ path: 'dichiarazione-sostitutiva', component: DichiarazioneSostitutivaComponent },
    { path: '**', redirectTo: '/home', pathMatch: 'full' }
  ])],
  exports: [RouterModule]
})
export class AppRoutingModule { }
