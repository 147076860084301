<app-header></app-header>
<div class="page-content">
    <div>
        <button type="button" (click)="navigateTo('/listaPersonale')" class="btn button-docenti">Gestione Persone RSA
        </button>
        <button type="button" (click)="navigateTo('/listaPrescrizioni')" class="btn button-active-prescrizione">Gestione
            Codice RSA</button>
    </div>
    <div class="sezione-titolo">
        <h3>Ricerca Persone RSA e Codice RSA</h3>
    </div>

    <!-- form start -->
    <form name='form' role="form" class="form" [formGroup]="ricercaForm" (ngSubmit)="cerca()">
        <div class="row sezione-textEditor">
            <div class="form-group col-12 col-md-3">
                <label for="cognome" class="control-label">Cognome: </label>
                <input class="form-control form-textEditor form-control-sm" type="text" id='cognome'
                    formControlName="cognome">
                <validation-messages [control]="ricercaForm.controls.cognome"></validation-messages>

            </div>
            <div class="form-group col-12 col-md-3">
                <label class="control-label">Nome: </label>
                <input class="form-control form-textEditor form-control-sm" type="text" id='nome'
                    formControlName='nome'>
                <validation-messages [control]="ricercaForm.controls.nome"></validation-messages>
            </div>
            <div class="form-group col-12 col-md-3">
                <label class="control-label">Codice Fiscale: </label>
                <input class="form-control form-textEditor form-control-sm" type="text" id='codiceFiscale'
                    formControlName='cfOperatore'>
                <validation-messages [control]="ricercaForm.controls.cfOperatore"></validation-messages>
            </div>
        </div>
        <div class="row sezione-checkBox">
            <div class="form-check col-12 col-md-2">
                <input type="checkbox" class="form-check-input" id="prescrizione_no" formControlName='prescrizione_no'
                    (change)="getPrescrizioni()">
                <label class="form-check-label" for="prescrizione_no">Non Generato</label>
            </div>
            <div class="form-check col-12 col-md-2">
                <input type="checkbox" class="form-check-input" id="prescrizione_si" formControlName='prescrizione_si'
                    (change)="getPrescrizioni()">
                <label class="form-check-label" for="prescrizione_si">Generato</label>
            </div>
            <div class="form-check col-12 col-md-2">
                <input type="checkbox" class="form-check-input" id="prenotazione_si" formControlName='prenotazione_si'
                    (change)="getPrescrizioni()">
                <label class="form-check-label" for="prenotazione_si">Prenotato</label>
            </div>
            <!--
            <div class="form-check col-12 col-md-4">
                <input type="checkbox" class="form-check-input" id="erogato_si" formControlName='erogato_si'
                    (change)="getPrescrizioni()">
                <label class="form-check-label" for="erogato_si">Erogato</label>
            </div>
            -->
        </div>
        <div class="row justify-content-end sezione-border">
            <div class="col-12 col-md-12">
                <div class="align-right">
                    <button class="btn button-prescrizione" type="button" (click)="pulisciCampi()">Pulisci
                        campi</button>
                    <button class="btn button-prescrizione" type="submit">Cerca</button>
                </div>
            </div>
        </div>
    </form>
    <!-- form -->

    <div class="sezione-titolo">
        <h3 *ngIf="showPrescrizioniList && prescrizioneList.length > 0">Risultato della ricerca</h3>
        <span *ngIf="showPrescrizioniList && prescrizioneList.length > 0"><span
                style="color:Tomato;font-size: 1.5em"><b>*</b></span> E' possibile generare un massimo di due codici RSA
            nel mese solare per ciascun soggetto</span>


        <h3 *ngIf="showPrescrizioniList && prescrizioneList.length == 0">Nessun Codice RSA presente</h3>
    </div>

    <div *ngIf="showPrescrizioniList ">
        <div class="row sezione-paginazione">
            <div class="col-12 col-md-3">
            </div>
            <div class="col-12 col-md-6">
                <nav>
                    <ul class="pagination justify-content-center">
                        <li class="pagination-text">Visualizzati
                            {{calcolaPrimoValoreElementiVisualizzati()}}-{{calcolaSecondoValoreElementiVisualizzati()}}
                            di
                            {{totalElements}}: </li>
                        <li class="page-item"><a class="page-link" (click)="decreasePage()"><i
                                    class="fas fa-caret-left"></i></a>
                        </li>
                        <li class="page-item"><a class="page-link">{{this.page}}</a></li>
                        <li class="page-item"><a class="page-link" (click)="increasePage()"><i
                                    class="fas fa-caret-right"></i></a></li>
                    </ul>
                </nav>
            </div>
            <div class="col-12 col-md-3 align-right">
                <label class="control-label">Visualizza Elementi: </label>
                <select class="form-control form-control-sm select-elementi" id="opzione" [(ngModel)]="elements"
                    (change)="changeTotalElements()">
                    <option *ngFor="let option of visualizzaElementiList" value="{{option}}">{{option}}</option>
                </select>
            </div>
        </div>

        <table class="table table-responsive-xl" id="listaPrescrizioniTable">
            <thead>
                <tr>
                    <th *ngFor="let header of headerList" scope="col" class="align-{{header.align}} table-prescrizioni">
                        <input *ngIf="header.value == 'RICHIEDI'" class="checkbox-header" type="checkbox"
                            id="checkAll-richiesta" (click)="selectAllRichiesta()">
                        <input *ngIf="header.value == 'STAMPA'" class="checkbox-header" type="checkbox"
                            id="checkAll-stampa" (click)="selectAllStampa()">
                        {{header.value}}
                        <i style="cursor:pointer" *ngIf="header.orderDirection == 'ASC'"
                            (click)="setOrdinamento(header)" class=" fa fa-arrow-up"></i>
                        <i style="cursor:pointer" *ngIf="header.orderDirection == 'DESC'"
                            (click)="setOrdinamento(header)" class=" fa
                            fa-arrow-down"></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let prescrizione of prescrizioneList, let i = index">
                    <td *ngFor="let property of propertyList" scope="col" class="align-{{property.align}}">
                        {{prescrizione[property.value]}}
                    </td>
                    <th scope="col" class="table-prescrizioni align-center">
                        <input type="checkbox" *ngIf="prescrizione.richiedibile" id=" checkAll"
                            id="check-richiesta-{{i}}" (click)="selectPrescrizioneRichiesta(prescrizione,i)">
                        <span
                            *ngIf="!prescrizione.richiedibile && prescrizione.stato != 'Generato' && prescrizione.stato != 'Prenotato'"
                            id="checkAll"><span style="font-size: 1.5em"><b>*</b></span> N.D.</span>
                    </th>
                    <th scope="col" class="table-prescrizioni align-center"> <input type="checkbox"
                            *ngIf="verificaStampa(prescrizione)" id="checkAll" id="check-stampa-{{i}}"
                            (click)="selectPrescrizioneStampa(prescrizione,i)"></th>
                    <td class="align-center table-prescrizioni"><button *ngIf="prescrizione.hasStorico"
                            class="button-awesome-prescrizioni" (click)="storicoRichieste(prescrizione)"><i
                                class="fa fa-history"></i></button></td>
                </tr>
            </tbody>
        </table>

        <div class="row justify-content-end">
            <div class="align-right">
                <button type="button" class="btn button-prescrizione" (click)=richiediTampone()>Genera codice RSA
                    per le persone selezionate</button>
                <button type="button" class="btn button-prescrizione" (click)=stampaPersonaleSelezionato()>Stampa codice
                    RSA per le persone selezionate</button>
            </div>
        </div>
    </div>

</div>
<app-footer></app-footer>

<div *ngIf="modalStampaNRE.open" class="modale-background">
    <div id="modale-stampaNre-content" class="modale-stampaNre-content">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{modalStampaNRE.title}}</h5>
                    <button type="button" class="close" (click)="modalStampaNRE.closeModal()" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <p class="label-modal">RSA: </p>
                        <p>{{istituto.codice}} - {{istituto.descrizione}}</p>
                    </div>
                    <div class="row">
                        <p class="label-modal">Data: </p>
                        <p>{{dataStampa}}</p>
                    </div>
                    <table class="table table-responsive-sm" id="listaStampa">
                        <thead>
                            <tr>
                                <th *ngFor="let header of headerListStampaTable" scope="col"
                                    class="align-{{header.align}} table-prescrizioni">{{header.value}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let prescrizione of prescrizioneListSelectedStampa, let i = index">
                                <td *ngFor="let property of propertyListStampaTable" scope="col"
                                    class="align-{{property.align}}">
                                    {{prescrizione[property.value]}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="pagination-text">Totale elementi: {{prescrizioneListSelectedStampa.length}}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn button-prescrizione" (click)="stampaNre()"
                        data-dismiss="modal">Stampa</button>
                    <button type="button" class="btn button-prescrizione" (click)="modalStampaNRE.closeModal()"
                        data-dismiss="modal">Chiudi</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="modalStorico.open" class="modale-background">
    <div class="modale-storico-content">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{modalStorico.title}}</h5>
                    <button type="button" class="close" (click)="modalStorico.closeModal()" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <p class="label-modal">*Sono presenti esclusivamente i tamponi già erogati </p>
                    </div>
                    <div class="row">
                        <p class="label-modal">Cognome: </p>
                        <p>{{personaleSelezionato.cognome}}</p>
                    </div>
                    <div class="row">
                        <p class="label-modal">Nome: </p>
                        <p>{{personaleSelezionato.nome}}</p>
                    </div>
                    <div class="row">
                        <p class="label-modal">Codice Fiscale: </p>
                        <p>{{personaleSelezionato.cfOperatore}}</p>
                    </div>
                    <div class="row">
                        <p class="label-modal">Ruolo: </p>
                        <p>{{personaleSelezionato.descOperatore}}</p>
                    </div>
                    <div class="row">
                        <p class="label-modal">Istituto: </p>
                        <p>{{istituto.codice}} - {{istituto.descrizione}}</p>
                    </div>
                    <table class="table table-responsive-sm" id="listaStampa">
                        <thead>
                            <tr>
                                <th *ngFor="let header of headerListStoricoTable" scope="col"
                                    class="align-{{header.align}} table-prescrizioni">{{header.value}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let storico of storicoList, let i = index">
                                <td *ngFor="let property of propertyListStoricoTable" scope="col"
                                    class="align-{{property.align}}">
                                    {{storico[property.value]}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn button-docenti" (click)="modalStorico.closeModal()"
                        data-dismiss="modal">Chiudi</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="modal_error.open" class="modale-background">
    <div class="modale-content">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{modal_error.title}}</h5>
                    <button type="button" class="close" (click)="modal_error.closeModal()" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    {{modal_error.message}}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn button-docenti" (click)="modal_error.closeModal()"
                        data-dismiss="modal">OK</button>
                </div>
            </div>
        </div>
    </div>