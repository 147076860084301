import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class GuardService {

  constructor(private router : Router, private auth: AuthService) { }

  canActivate(next : ActivatedRouteSnapshot, state : RouterStateSnapshot):
  boolean{
    let boolean = true;
    if(!this.auth.getUtente()){
      this.router.navigate(['/home']);
      boolean = false;
    } 
    return boolean;
  }
}
