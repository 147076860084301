<div *ngIf="!home" class="align-right">
  <h3 *ngIf="!cittadino" class="cod-scuola">{{istituto.codice}} - {{istituto.descrizione}} <p *ngIf="flagCambiaIstituto"
      class="cambia-istituto" (click)="cambiaIstituto()">Cambia RSA </p>
  </h3>
  <div class="btn-group">
    <button type="button"
      [className]="prescrizioniPage ? 'btn button-prescrizione dropdown-toggle' : 'btn button-docenti dropdown-toggle'"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {{utente.name}} {{utente.lastName}} <i class="fa fa-user"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-right">
      <button class="dropdown-item cambia-istituto" type="button" (click)="logout()">Logout</button>
    </div>
  </div>
</div>