import * as moment from 'moment';

export class Prescrizione {

    cognome: string;
    nome: string;
    cfOperatore: string;
    dataNascita: string;
    telefono: string;
    email: string;
    descOperatore: string;
    nreT: string;
    stato: string;
    richiedibile: Boolean;
    hasStorico: Boolean;

    constructor(prescrizione) {
        moment.locale("it");

        this.cognome = prescrizione.cognome;
        this.nome = prescrizione.nome;
        this.cfOperatore = prescrizione.cfOperatore.toUpperCase();
        this.dataNascita = moment(prescrizione.dataNascita).format("L");
        this.telefono = prescrizione.telefono;
        this.email = prescrizione.email;
        this.descOperatore = prescrizione.descOperatore;
        this.nreT = prescrizione.nreT ? prescrizione.nreT : '-';
        //this.stato = ((prescrizione.stato == "Erogato") || (prescrizione.stato == "Prenotato")) ? "Generato" : prescrizione.stato;
        this.stato = prescrizione.stato;
        this.richiedibile = prescrizione.richiedibile;
        this.hasStorico = prescrizione.hasStorico;

    }

}