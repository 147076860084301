import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Costanti } from './../../utility/costanti';
import { Anagrafica } from './../../model/anagrafica';
import { AuthService } from '../../service/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValidationService } from './../../service/form/validation.service';
import { PersonaleScolasticoService } from './../../service/personale-scolastico.service';

import * as moment from 'moment';
import { ModalService } from 'src/app/service/modale/modal.service';

@Component({
  selector: 'app-dettaglio-docente',
  templateUrl: './dettaglio-docente.component.html',
  styleUrls: ['./dettaglio-docente.component.css']
})
export class DettaglioDocenteComponent implements OnInit {
  censimentoCF: any;
  datiDocente: any;
  anagraficaDocente: Anagrafica;
  campiAnagrafica: any[];
  modificaView: boolean;
  listaRuoli: any[];
  disableCf: Boolean;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private personaleService: PersonaleScolasticoService,
    private modalService: ModalService,
    private router: Router,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.modificaView = false;
    this.buildCensimentoCf_Form();
    this.anagraficaDocente = new Anagrafica();
    this.campiAnagrafica = this.anagraficaDocente.getPropertyList();
    this.getListaRuoli();
  }

  buildCensimentoCf_Form() {
    let docente = this.auth.getDocente();
    if (docente != null) {
      this.modificaView = true;
      let params = { cf: docente.cfOperatore }
      this.cercaAnagrafica(params);
      this.censimentoCF = this.formBuilder.group({
        cf: [docente.cfOperatore, [Validators.required, ValidationService.validateCFLength]]
      });
      this.censimentoCF.get("cf").disable();
      this.datiDocente = this.formBuilder.group({
        cell: [docente.telefono, [ValidationService.validatePhone]],
        email: [docente.email, [ValidationService.validateEmail]],
        ruolo: [docente.codeOperatore]

      })
    } else {
      this.modificaView = false;
      this.censimentoCF = this.formBuilder.group({
        cf: ['', [Validators.required, ValidationService.validateCFLength]]
      });

      this.datiDocente = this.formBuilder.group({
        cell: ['', [ValidationService.validatePhone]],
        email: ['', [ValidationService.validateEmail]],
        ruolo: [0]
      })
    }
  }


  pulisciCampi() {
    this.censimentoCF.get("cf").enable();
    this.censimentoCF.reset();
    this.datiDocente.reset();
    this.datiDocente = this.formBuilder.group({
      cell: ['', [ValidationService.validatePhone]],
      email: ['', [ValidationService.validateEmail]],
      ruolo: [0]
    });
    this.anagraficaDocente = new Anagrafica();
  }

  cercaAnagrafica(formValue) {
    if (formValue.cf) {
      if (formValue.cf.length == 16) {

        let params = {
          codiceScuola: this.auth.getCodiceScuola(),
          cfOperatore: formValue.cf.trim()
        }
        this.spinner.show()
        this.personaleService.getAnagraficaPersonaleCensito(params).subscribe(
          result => {
            this.spinner.hide()
            if (result.response) {
              this.censimentoCF.get("cf").disable();
              result.response.dataNascita = result.response.dataNascita;
              this.anagraficaDocente = result.response;
            }
            if (!this.anagraficaDocente.uidOperatore) {
              this.censimentoCF.get("cf").enable();
              this.modalService.openModalGlobal("ATTENZIONE", "Errore nel recupero dell'anagrafica", "OK")
            }
          },
          error => {
            this.spinner.hide()
            this.modalService.openModalGlobal("ATTENZIONE", "Errore nel recupero dell'anagrafica", "OK")
          }
        )
      } else {
        this.modalService.openModalGlobal("ATTENZIONE", "Inserire codice fiscale valido", "OK")

      }
    } else {
      this.modalService.openModalGlobal("ATTENZIONE", "Inserire codice fiscale", "OK")
    }
  }


  censisciDocente() {
    let body = {
      cfOperatore: this.anagraficaDocente.cfOperatore,
      cognome: this.anagraficaDocente.cognome,
      nome: this.anagraficaDocente.nome,
      dataNascita: moment(this.anagraficaDocente.dataNascita, 'DD/MM/yyyy').format("DD/MM/yyyy"),
      comuneNascita: this.anagraficaDocente.comuneNascita,
      provinciaNascita: this.anagraficaDocente.provinciaNascita,
      genere: this.anagraficaDocente.genere,
      email: this.datiDocente.value.email,
      telefono: this.datiDocente.value.cell,
      codeOperatore: this.datiDocente.value.ruolo,
      uidOperatore: this.anagraficaDocente.uidOperatore
    }

    //body.cfOperatore.trim();

    if (this.bodyIsValid(body)) {
      let params = {
        codiceScuola: this.auth.getCodiceScuola()
      }
      this.spinner.show()
      this.personaleService.savePersonaleScolastico(params, body).subscribe(
        result => {
          this.spinner.hide()
          this.auth.setDocente(null)
          this.router.navigate(["/listaPersonale"])
        },
        error => {
          this.spinner.hide()
          this.modalService.openModalGlobal("ATTENZIONE", "Errore nel salvataggio", "OK")
        }
      )
    }
  }

  getListaRuoli() {
    let params = {
      codiceScuola: this.auth.getCodiceScuola()
    }
    this.spinner.show();
    this.personaleService.ruoliOperatore(params).subscribe(
      result => {
        this.spinner.hide();
        this.listaRuoli = this.ordinaRuoliPerCodice(result.response);
      },
      error => {
        this.spinner.hide();
        this.modalService.openModalGlobal("ATTENZIONE", "Errore nel recupero dei ruoli operatore", "OK")
      }
    )
  }

  ordinaRuoliPerCodice(listaRuoli) {
    listaRuoli.sort(function (a, b) {
      if (a.codOperatore > b.codOperatore) {
        return -1;
      }
    });
    return listaRuoli;
  }


  bodyIsValid(body) {
    let isValid = true;
    if (this.datiDocente.status == "INVALID") {
      this.modalService.openModalGlobal("ATTENZIONE", "Controllare la correttezza dei campi", "OK")
      isValid = false;
    } else if (this.censimentoCF.status == "INVALID") {
      this.modalService.openModalGlobal("ATTENZIONE", "Inserire dati obbligatori", "OK")
      isValid = false;
    }
    return isValid;
  }

  goToListaDocenti() {
    this.auth.setDocente(null);
    this.navigateTo("/listaPersonale");
  }

  generateRandomString() {
    var sRnd = "";
    var sChrs = "ABCDEFGHIJKLMNOPQRSTUVWXTZ";
    for (var i = 0; i < 3; i++) {
      var randomPoz = Math.floor(Math.random() * sChrs.length);
      sRnd += sChrs.substring(randomPoz, randomPoz + 1);
    }
    return sRnd;
  }

  generateUid() {
    var randomString = this.generateRandomString();
    var protocollo = 'REG201999999' + randomString;
    return protocollo;
  }

  navigateTo(url) {
    this.router.navigate([url])
  }

}
