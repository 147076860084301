import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from './../../service/form/validation.service';
import { Costanti } from './../../utility/costanti';

@Component({
  selector: 'validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.css']
})
export class ValidationMessagesComponent implements OnInit {
  errorMessage: any;
  @Input() control: FormControl;

  constructor() { }

  ngOnInit(): void {
    this.errorMessage = Costanti.ERROR_MESSAGES_FORM;
  }



}
